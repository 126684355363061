import React, { Component } from 'react'
import './style.scss'
import NavbarComponent from '../Navbar'
import { WITHOUT_FOOTER_PAGES } from '../../constants/route'
import { matchPath, withRouter } from 'react-router-dom'

class FooterComponent extends Component {
	render() {
		const { location } = this.props
		const match = WITHOUT_FOOTER_PAGES.find((path) => {
			return matchPath(location.pathname, {
				path: path,
				exact: true,
			})
		})
		return (
			<footer
				className="app-footer"
				style={{ display: match ? 'none' : 'flex' }}
			>
				<NavbarComponent {...this.props} showLogin={false} />
			</footer>
		)
	}
}

export default withRouter(FooterComponent)
