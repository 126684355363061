import { Typography } from '@material-ui/core'
import React from 'react'
import { COLOR } from '../../../../constants/theme'
import WhatsAppBtn from '../WhatsAppBtn'
import DonationButton from '../DonationButton'
import ActionButtons from './ActionButtons'

const SubsidyApproved = ({ name, referrer, finalAmount }) => {
	return (
		<div className="success-screen">
			<div className="mb-16">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					x="0px"
					y="0px"
					width={'50'}
					height={'50'}
					viewBox="0 0 375.147 375.147"
					fill={COLOR.PRIMARY}
				>
					<polygon points="344.96,44.48 119.147,270.293 30.187,181.333 0,211.52 119.147,330.667 375.147,74.667 		" />
				</svg>
			</div>
			<Typography variant="h3" className="mb-16">
				{`Hare Krishna, ${
					name ? name + ' ' : ''
				}! Your subsidized payment request has been approved.`}
			</Typography>
			<Typography variant="h4" className="mb-16">
				Please pay <b>₹{finalAmount}</b> now to expedite the process.
			</Typography>
			<Typography variant="body1" className="mb-16">
				The details would be forwarded to the concerned area leader and
				facilitator. You would be soon informed by the status. Stay
				tuned.
			</Typography>
			<DonationButton />
			<WhatsAppBtn referrer={referrer} />
			<ActionButtons />
		</div>
	)
}

export default SubsidyApproved
