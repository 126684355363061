import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import DashboardContainer from '../../containers/Dashboard'
import PrivateRouter from './PrivateRouter'
import Header from '../Header'
import { ROUTE } from '../../constants/route'
import LoginContainer from '../../containers/Login'
import AnswerQuestionContainer from '../../containers/AnswerQuestion'
import ManageAreaContainer from '../../containers/ManageArea'
import ViewPledging from '../../containers/Pledging/ViewPledging'
import UserContainer from '../../containers/UserContainer'
import PrivacyContainer from '../../containers/Privacy'
import PledgingContainer from '../../containers/Pledging'
import ManageAppContent from '../../containers/ManageAppContent'
import ViewAreaPledges from '../../containers/Pledging/ViewAreaPledges'
import NotificationContainer from '../../containers/Notification'
import ScheduleNotificationsContainer from '../../containers/ScheduleNotifications'
import Register from '../../containers/Register'
import ResetPassword from '../../containers/ResetPassword'
import UpdateFrontliners from '../../containers/UpdateFrontliners'
import ReportContainer from '../../containers/Reports'
import ViplavaRegister from '../../containers/EventRegister/shared-pages/ViplavaRegister'
import EventContainer from '../../containers/ManageEvent'
import RefundPolicy from '../../containers/RefundPolicy'
import Terms from '../../containers/Terms'
import Footer from '../Footer'
import About from '../../containers/About'
import Contact from '../../containers/ContactUs'
import ViplavaBridge from '../../containers/EventRegister/shared-pages/ViplavaBridge'
import EventLandingPage from '../../containers/EventRegister/shared-pages'
import CreateEventContainer from '../../containers/CreateEvent'
import EventListing from '../../containers/EventRegister/shared-pages/EventListing'
import Viplava2023 from '../../containers/EventRegister/particular-pages/Viplava2023'
import Donate from '../../containers/Donate'
import LibraryContainer from '../../containers/LibraryContainer'
import { EVENTS_UI_CONFIG } from '../../data/event'
import HaridwarCamp from '../../containers/EventRegister/particular-pages/HaridwarCamp2023'
import ManageQRScreen from '../../containers/ManageAttendance/ManageQR'
import AttendanceScreen from '../../containers/ManageAttendance/AttendanceScreen'

class RouterComponent extends Component {
	render() {
		return (
			<Router>
				<Header />
				<Switch>
					<Route
						path={ROUTE.HOME.path}
						component={LoginContainer}
						exact
					/>
					<Route
						path={ROUTE.PRIVACY.path}
						component={PrivacyContainer}
						exact
					/>
					<Route
						path={ROUTE.REGISTER.path}
						component={Register}
						exact
					/>
					<Route
						path={ROUTE.VIPLAVA.path}
						component={Viplava2023}
						exact
					/>
					<Route
						path={ROUTE.DONATION_PAGE.path}
						component={Donate}
						exact
					/>
					<Route
						path={ROUTE.EVENT.path}
						component={EventLandingPage}
						exact
					/>
					<Route
						path={ROUTE.EVENT_BRIDGE.path}
						component={ViplavaBridge}
						exact
					/>
					<Route
						path={ROUTE.EVENT_REGISTER.path}
						component={ViplavaRegister}
						exact
					/>
					<Route
						path={ROUTE.EVENT_REGISTER_FOR_APP.path}
						component={ViplavaRegister}
						exact
					/>
					<Route path={ROUTE.TNC.path} component={Terms} exact />
					<Route path={ROUTE.ABOUT.path} component={About} exact />
					<Route
						path={ROUTE.CONTACT.path}
						component={Contact}
						exact
					/>
					<Route
						path={ROUTE.REFUND_POLICY.path}
						component={RefundPolicy}
						exact
					/>
					{/* <Route path={ROUTE.VIPLAVA_REGISTER.path} component={ViplavaRegister} exact /> */}
					<Route
						path={ROUTE.VIPLAVA_BRIDGE.path}
						component={ViplavaBridge}
						exact
					/>

					{/* <Route
						path={ROUTE.BSS_CAMP.path}
						component={() => (
							<HaridwarCamp eventId="bss-dps-haridwar-camp-2024" />
						)}
						exact
					/>
					<Route
						path={ROUTE.SPS_CAMP.path}
						component={() => (
							<HaridwarCamp eventId="sps-vl2-haridwar-camp-2024" />
						)}
						exact
					/> */}

					<Route
						path={ROUTE.BSS_BRAJA.path}
						component={() => (
							<HaridwarCamp eventId="bss-braja-camp-2024" />
						)}
						exact
					/>

					{/* <Route
						path={ROUTE.FTP12024.path}
						component={() => (
							<ViplavaBridge
								eventIdFromProps={
									EVENTS_UI_CONFIG.find(
										(event) => event.id === 'ftp12024'
									)?.uuid
								}
							/>
						)}
						exact
					/> */}
					{/* <Route
						path={ROUTE.MAYAPYR2024.path}
						component={JagannathPuriMayapurCamp}
						exact
					/> */}
					{/* <Route
						path={ROUTE.PURI2024.path}
						component={JagannathPuriMayapurCamp}
						exact
					/> */}
					{/* <Route
						path={ROUTE.BOTHMNP.path}
						component={JagannathPuriMayapurCamp}
						exact
					/> */}

					<PrivateRouter
						path={ROUTE.ATTENDANCE_MANAGE_QR.path}
						route={ROUTE.ATTENDANCE_MANAGE_QR}
						component={ManageQRScreen}
						exact
					/>

					<PrivateRouter
						path={ROUTE.ATTENDANCE_MANAGE.path}
						route={ROUTE.ATTENDANCE_MANAGE}
						component={AttendanceScreen}
						exact
					/>

					<PrivateRouter
						path={ROUTE.DASHBOARD.path}
						route={ROUTE.DASHBOARD}
						component={DashboardContainer}
						exact
					/>
					<PrivateRouter
						path={ROUTE.ANSWER_QUESTION.path}
						route={ROUTE.ANSWER_QUESTION}
						component={AnswerQuestionContainer}
						exact
					/>
					<PrivateRouter
						path={ROUTE.MANAGE_AREA.path}
						route={ROUTE.MANAGE_AREA}
						component={ManageAreaContainer}
						exact
					/>
					<PrivateRouter
						path={ROUTE.PLEDGING.path}
						route={ROUTE.PLEDGING}
						component={PledgingContainer}
						exact
					/>
					<PrivateRouter
						path={ROUTE.UPDATE_FRONTLINERS.path}
						route={ROUTE.UPDATE_FRONTLINERS}
						component={UpdateFrontliners}
						exact
					/>
					<PrivateRouter
						path={ROUTE.REPORTS.path}
						route={ROUTE.REPORTS}
						component={ReportContainer}
						exact
					/>
					<PrivateRouter
						path={ROUTE.VIEW_PLEDGE_SUMMARY.path}
						route={ROUTE.VIEW_PLEDGE_SUMMARY}
						component={ViewPledging}
						exact
					/>
					<PrivateRouter
						path={ROUTE.VIEW_PLEDGE_AREAWISE.path}
						route={ROUTE.VIEW_PLEDGE_AREAWISE}
						component={ViewAreaPledges}
					/>
					<PrivateRouter
						path={ROUTE.NOTIFICATION.path}
						route={ROUTE.NOTIFICATION}
						component={NotificationContainer}
						exact
					/>
					<PrivateRouter
						path={ROUTE.MANAGE_APP_CONTENT.path}
						route={ROUTE.MANAGE_APP_CONTENT}
						component={ManageAppContent}
						exact
					/>
					<PrivateRouter
						path={ROUTE.USER.path}
						route={ROUTE.USER}
						component={UserContainer}
						exact
					/>
					<PrivateRouter
						path={ROUTE.SCHEDULE_NOTIFICATION.path}
						route={ROUTE.SCHEDULE_NOTIFICATION}
						component={ScheduleNotificationsContainer}
						exact
					/>
					<PrivateRouter
						path={ROUTE.RESET_PASSWORD.path}
						route={ROUTE.RESET_PASSWORD}
						component={ResetPassword}
						exact
					/>
					<PrivateRouter
						path={ROUTE.EVENTS.path}
						route={ROUTE.EVENTS}
						component={EventContainer}
						exact
					/>
					<PrivateRouter
						path={ROUTE.LIBRARY.path}
						route={ROUTE.EVENTS}
						component={LibraryContainer}
						exact
					/>
					<PrivateRouter
						path={ROUTE.EVENT_CREATE_PAGE.path}
						route={ROUTE.EVENT_CREATE_PAGE}
						component={CreateEventContainer}
						exact
					/>
					<Route
						path={ROUTE.EVENT_LIST_PAGE.path}
						component={EventListing}
						exact
					/>
				</Switch>
				<Footer />
			</Router>
		)
	}
}

export default RouterComponent
