export const ROUTE = {
	HOME: { path: '/', roles: [4, 5, 6, 7] },
	LOGIN: { path: '/login', roles: [4, 5, 6, 7] },
	SCHEDULE_NOTIFICATION: {
		path: '/schedule-notification',
		roles: [6, 7],
	},
	DASHBOARD: { path: '/dashboard', roles: [4, 5, 6, 7] },
	ANSWER_QUESTION: { path: '/answer-question', roles: [4, 5, 6, 7] },
	MANAGE_AREA: { path: '/area', roles: [4, 5, 6, 7] },
	ALL_USER: { path: '/user/all', roles: [4, 5, 6, 7] },
	PENDING_USER: { path: '/user/pending', roles: [4, 5, 6, 7] },
	APPROVED_USER: { path: '/user/approved', roles: [4, 5, 6, 7] },
	NON_COASTOK_USER: { path: '/user/non-coastok', roles: [4, 5, 6, 7] },
	INACTIVE_USER: { path: '/user/inactive', roles: [6, 7] },
	USER: { path: '/user/:pageType', roles: [4, 5, 6, 7] },
	PROFILE: { path: '/profile', roles: [4, 5, 6, 7] },
	PRIVACY: { path: '/privacy', roles: [4, 5, 6, 7] },
	PLEDGING: { path: '/pledging', roles: [4, 5, 6, 7] },
	VIEW_PLEDGE_SUMMARY: { path: '/pledging/:pledgeId', roles: [5, 6, 7] },
	VIEW_PLEDGE_AREAWISE: {
		path: '/pledging/:pledgeId/:areaId',
		roles: [4, 5, 6, 7],
	},
	NOTIFICATION: { path: '/notification', roles: [4, 6, 7] },
	MANAGE_APP_CONTENT: { path: '/publish/:type', roles: [6, 7] },
	MANAGE_VIDEOS: { path: '/publish/videos', roles: [6, 7] },
	MANAGE_IMAGES: { path: '/publish/images', roles: [6, 7] },
	REGISTER: { path: '/register' },
	RESET_PASSWORD: { path: '/resetPassword', roles: [4, 5, 6, 7] },
	UPDATE_FRONTLINERS: { path: '/assignFrontliners', roles: [4, 5, 6, 7] },
	REPORTS: { path: '/reports', roles: [6, 7] },

	VIPLAVA: { path: '/viplava' }, // 1
	EVENT: { path: '/event/:eventId' },
	EVENT_PAGE: { path: '/event' },
	EVENT_LIST_PAGE: { path: '/events' },
	EVENT_REGISTER: { path: '/event/register/:eventId' },
	EVENT_REGISTER_FOR_APP: { path: '/event/:eventId/register' },
	EVENT_REGISTER_PAGE: { path: '/event/register' },
	EVENT_BRIDGE: { path: '/event/bridge/:eventId' },
	EVENT_BRIDGE_PAGE: { path: '/event/bridge' },
	VIPLAVA_REPORT: { path: '/viplava-report', roles: [4, 5, 6, 7] },
	VIPLAVA_REGISTER: { path: '/viplava/register' }, // 3
	VIPLAVA_BRIDGE: { path: '/viplava/bridge' }, // 2
	EVENTS: { path: '/event/manage/:eventId', roles: [4, 5, 6, 7] },
	ATTENDANCE: { path: '/attendance', roles: [4, 5, 6, 7] },
	ATTENDANCE_MANAGE_QR: {
		path: '/attendance/manage/qr',
		roles: [4, 5, 6, 7],
	},
	ATTENDANCE_MANAGE: { path: '/attendance/users', roles: [4, 5, 6, 7] },
	LIBRARY: { path: '/library/:libraryType', roles: [4, 5, 6, 7] },
	LIBRARY_READING: { path: '/library/reading', roles: [4, 5, 6, 7] },
	LIBRARY_HEARING: { path: '/library/hearing', roles: [4, 5, 6, 7] },
	EVENT_MANAGE_PAGE: { path: '/event/manage', roles: [4, 5, 6, 7] },
	EVENT_CREATE_PAGE: { path: '/event/create', roles: [6] },
	DONATION_PAGE: { path: '/viplavadonate' },
	REFUND_POLICY: { path: '/refund' },
	TNC: { path: '/terms' },
	ABOUT: { path: '/about-us' },
	CONTACT: { path: '/contact-us' },
	// FTP12024: { path: '/ftp12024' },

	// Archieved paths

	// MAYAPYR2024: { path: '/mayapur2024' }, // 1
	// PURI2024: { path: '/puri2024' }, // 1
	// BOTHMNP: { path: '/bothmnp' }, // 1
	// HARIDWAR: { path: '/haridwar' },
	// SPS_CAMP: { path: '/spssummer2024' },
	// BSS_CAMP: { path: '/bsssummer2024' },
	BSS_BRAJA: { path: '/bssbraja2024' },
}

export const WITHOUT_HEADER_PAGES = [
	ROUTE.VIPLAVA_REGISTER.path,
	ROUTE.VIPLAVA.path,
	ROUTE.VIPLAVA_BRIDGE.path,
	ROUTE.EVENT.path,
	ROUTE.EVENT_REGISTER.path,
	ROUTE.EVENT_BRIDGE.path,
	ROUTE.EVENT_REGISTER_FOR_APP.path,
	ROUTE.EVENT_LIST_PAGE.path,
	ROUTE.DONATION_PAGE.path,
	// ROUTE.FTP12024.path,
	// ROUTE.MAYAPYR2024.path,
	// ROUTE.PURI2024.path,
	// ROUTE.BOTHMNP.path,
	// ROUTE.SPS_CAMP.path,
	// ROUTE.BSS_CAMP.path,
	ROUTE.BSS_BRAJA.path,
]

export const ADMIN_PANEL_SITES = [
	ROUTE.VIPLAVA_REGISTER.path,
	ROUTE.VIPLAVA.path,
	ROUTE.VIPLAVA_BRIDGE.path,
	ROUTE.EVENT.path,
	ROUTE.EVENT_REGISTER.path,
	ROUTE.EVENT_BRIDGE.path,
	ROUTE.EVENT_REGISTER_FOR_APP.path,
	ROUTE.EVENT_LIST_PAGE.path,
	ROUTE.DASHBOARD.path,
	ROUTE.ATTENDANCE_MANAGE.path,
	ROUTE.ATTENDANCE_MANAGE_QR.path,
]

export const WITHOUT_FOOTER_PAGES = [
	ROUTE.VIPLAVA_REGISTER.path,
	ROUTE.VIPLAVA.path,
	ROUTE.VIPLAVA_BRIDGE.path,
	ROUTE.EVENT.path,
	ROUTE.EVENT_REGISTER.path,
	ROUTE.EVENT_BRIDGE.path,
	ROUTE.EVENT_REGISTER_FOR_APP.path,
	ROUTE.EVENT_LIST_PAGE.path,
	// ROUTE.FTP12024.path,
	// ROUTE.MAYAPYR2024.path,
	// ROUTE.SPS_CAMP.path,
	// ROUTE.BSS_CAMP.path,
	ROUTE.BSS_BRAJA.path,
]
