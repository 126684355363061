import { Button } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ROUTE } from '../../../constants/route'

function DonationButton() {
	const history = useHistory()
	const onClickHandler = () => {
		history.push(`${ROUTE.DONATION_PAGE.path}`)
	}
	const styles = useMemo(() => ({ height: 15, width: 15, marginLeft: 5 }), [])
	return (
		<Button
			variant="contained"
			color="primary"
			className="mb-16"
			onClick={onClickHandler}
		>
			{'Donate '}
			<img
				src="https://res.cloudinary.com/dwneljbds/image/upload/v1690727942/xqniteq2oppjfs6ufl1s.png"
				alt="donate"
				style={styles}
			/>
		</Button>
	)
}

export default DonationButton
