import React, { Component } from 'react'
import { Button, Avatar } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { COLOR } from '../../constants/theme'
import { Link } from 'react-router-dom'
import { ROUTE } from '../../constants/route'
import { isUserloggedIn } from '../../utils'
import { ALERT, USER_ROLE } from '../../constants'
import { connect } from 'react-redux'
import './style.scss'
import { logoutAction } from '../../actions/auth'
import { changeUserRole } from '../../services/user'
import { showAlert, showSidebar, hideSidebar } from '../../actions'

class NavbarComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			anchorEl: null,
		}
	}

	handleClick = (event) => {
		const { drawerOpen, showSidebar, hideSidebar } = this.props
		this.setState({ anchorEl: event.currentTarget })
		if (drawerOpen) {
			hideSidebar()
		} else {
			showSidebar()
		}
	}

	handleClose = () => {
		this.setState({ anchorEl: null })
	}

	handleLogout = () => {
		const { logoutAction } = this.props
		logoutAction()
		localStorage.removeItem('Token')
		localStorage.removeItem('UserProfile')
		this.props.history.push(ROUTE.HOME.path)
		this.handleClose()
	}

	switchUserRole = (userId, role) => {
		const { showAlert } = this.props
		changeUserRole(userId, role).then(() => {
			this.handleLogout()
			showAlert({
				message:
					'You are switching your role. Please login with the same credentials again.',
				severity: ALERT.ERROR,
				duration: 5000,
			})
		})
	}

	render() {
		const { anchorEl } = this.state
		const { userProfile, showLinks = true, showLogin = true } = this.props
		const isLogin = !!userProfile || isUserloggedIn()
		const user =
			userProfile || JSON.parse(localStorage.getItem('UserProfile'))
		return (
			<nav className="navbar-container">
				{showLogin && (
					<ul>
						{isLogin ? (
							<li
								className="user-info"
								onClick={this.handleClick}
							>
								<Avatar>
									<AccountCircleIcon />
								</Avatar>
								<div className="user-details">
									<div className="name">
										{user.initiatedName || user.name}
									</div>
									<div className="role">
										{USER_ROLE[user.userRole]}
									</div>
								</div>
							</li>
						) : (
							<Link to={ROUTE.HOME.path}>
								<Button
									variant="contained"
									style={{ background: COLOR.WHITE }}
								>
									Login
								</Button>
							</Link>
						)}
					</ul>
				)}
				{showLinks && (
					<div className="nav-items">
						<>
							<Link to={ROUTE.ABOUT.path}>About COASTOK</Link>
							<Link to={ROUTE.CONTACT.path}>Contact Us</Link>
							<Link to={ROUTE.PRIVACY.path}>Privacy Policy</Link>
							<Link to={ROUTE.TNC.path}>
								Terms and Conditions
							</Link>
							<Link to={ROUTE.REFUND_POLICY.path}>
								Refund Policy
							</Link>
						</>
					</div>
				)}
			</nav>
		)
	}
}

const mapStateToProps = (state) => ({
	userProfile: state.commonContent.user,
	drawerOpen: state.commonContent.drawerOpen,
})

const mapDispatchToProps = {
	showAlert,
	logoutAction,
	showSidebar,
	hideSidebar,
}

export default connect(mapStateToProps, mapDispatchToProps)(NavbarComponent)
