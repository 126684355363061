import React, { useEffect, useReducer } from 'react'
import './style.scss'
import {
	Button,
	FormControl,
	FormGroup,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Modal,
	Paper,
	Select,
	TextField,
	makeStyles,
} from '@material-ui/core'
import { DataGrid, SearchIcon } from '@material-ui/data-grid'
import { createLibraryData, getLibraryData } from '../../services/library'
import { isMobileScreen, modifyProperty } from '../../utils'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import {
	LIBRARY_CATEGORY,
	LIBRARY_LANGUAGE,
	LIBRARY_MEDIA_TYPE,
	SADHNA_GROUP,
} from '../../constants'
import { CheckCircleRounded, Visibility } from '@material-ui/icons'

const useStyles = makeStyles({
	tableHeader: {
		padding: '20px',
		marginBottom: '20px',
	},
	modal: {
		display: 'flex',
		// padding: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'center',
	},
	modalRoot: {
		// height: 300,
		position: 'absolute',
		flexGrow: 1,
		minWidth: 300,
		transform: 'translateZ(0)',
		// The position fixed scoping doesn't work in IE 11.
		// Disable this demo to preserve the others.
		'@media all and (-ms-high-contrast: none)': {
			display: 'none',
		},
	},
	paper: {
		width: 400,
		backgroundColor: 'white',
		border: '2px solid #000',
		borderRadius: '8px',
		// boxShadow: theme.shadows[5],
		padding: '40px',
		lineHeight: '1.44',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
})
const initialState = {
	data: [],
	form: {
		lang: '',
		mediaType: '',
		category: '',
		sadhnaGroup: [],
		url: '',
		title: '',
	},
	modal: false,
	filters: {
		per_page: 10,
		page: 1,
		total_results: 0,
		total_pages: 0,
		language: '',
		mediaType: '',
		search: '',
		sadhnaGroup: [],
	},
}

function LibraryContainer(props) {
	const [{ data, modal, form, loading, filters }, setState] = useReducer(
		(prev, data) => ({
			...prev,
			...data,
			filters: { ...prev.filters, ...data.filters },
			form: { ...prev.form, ...data.form },
		}),
		initialState
	)
	const classes = useStyles()
	const isMobile = isMobileScreen()
	const params = useParams()
	const { libraryType } = params

	const fetchData = () => {
		const body = {
			id: '',
			category: LIBRARY_CATEGORY[libraryType].id,
			status: '',
			mediaType: filters.mediaType,
			sadhnaGroup: Array.isArray(filters.sadhnaGroup)
				? filters.sadhnaGroup?.join(',')
				: '',
			title: filters.search,
			lang: filters.language,
		}
		setState({
			loading: true,
		})
		getLibraryData(body).then((res) => {
			const { data, total_pages, total_results, current_page } =
				res?.data || {}
			setState({
				data,
				loading: false,
				filters: {
					total_results,
					page: current_page,
					total_pages,
				},
			})
		})
	}

	useEffect(() => {
		fetchData()
	}, [
		libraryType,
		filters.mediaType,
		filters.language,
		filters.search,
		filters.sadhnaGroup,
	])

	const rowWidthConfig = {
		title: { default: { flex: 0.2 }, mobile: { width: 150 } },
		action: { default: { flex: 0.2 }, mobile: { width: 150 } },
		language: { default: { flex: 0.2 }, mobile: { width: 150 } },
		mediaType: { default: { flex: 0.2 }, mobile: { width: 150 } },
		sadhnaGroup: { default: { flex: 0.2 }, mobile: { width: 150 } },
	}

	const headCells = [
		{
			field: 'title',
			numeric: false,
			headerName: 'Title',
			sortable: false,
		},
		{
			field: 'language',
			numeric: false,
			headerName: 'Language',
			sortable: false,
		},
		{
			field: 'mediaType',
			numeric: false,
			headerName: 'Media Type',
			sortable: false,
		},
		{
			field: 'sadhnaGroup',
			numeric: false,
			headerName: 'Sadhna Group',
			sortable: false,
		},
		{
			field: 'action',
			headerName: 'Action',
			renderCell: (params) => {
				return (
					<>
						<IconButton
							onClick={() => {
								window.open(params.row.url, '_blank')
							}}
							aria-label="View"
							color="primary"
							title="View"
						>
							<Visibility />
						</IconButton>
					</>
				)
			},
		},
	]

	const handleUserData = (params) => {} // TODO Implement this
	const onPageSizeChange = (params) => {} // TODO Implement this

	const onFilterChange = (name, value) => {
		setState({
			filters: {
				[name]: value,
				page: 1,
			},
		})
	}

	const handleInput = (name, value) => {
		setState({
			form: {
				...form,
				[name]: value,
			},
		})
	}

	const createAction = () => {
		const body = {
			category: LIBRARY_CATEGORY[libraryType].id,
			mediaType: form.mediaType,
			sadhnaGroup: Array.isArray(form.sadhnaGroup)
				? form.sadhnaGroup?.join(',')
				: '',
			title: form.title,
			lang: form.lang,
			url: form.url,
		}
		setState({
			loading: true,
		})
		createLibraryData(body).then((res) => {
			fetchData()
			setState({
				modal: false,
				loading: false,
				form: {
					category: '',
					mediaType: '',
					sadhnaGroup: [],
					title: '',
					lang: '',
					url: '',
				},
			})
		})
	}

	return (
		<div className="library-container">
			<Paper className={classes.tableHeader}>
				<div className="table-header-container">
					<div className="filter-header">
						<TextField
							id="input-with-icon-textfield"
							label="Search"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							name="search"
							onChange={(e) =>
								onFilterChange(e.target.name, e.target.value)
							}
							autoComplete="off"
							value={filters.search}
							className={'search-field'}
						/>

						<FormControl variant="outlined">
							<InputLabel id="library-language">
								Language
							</InputLabel>
							<Select
								labelId="library-language"
								id="filter-dropdown"
								value={filters.language}
								onChange={(e) =>
									onFilterChange(
										e.target.name,
										e.target.value
									)
								}
								label="Language"
								name="language"
							>
								<MenuItem value={''}>All Language</MenuItem>
								{LIBRARY_LANGUAGE.getAll().map((lang) => (
									<MenuItem value={lang.id}>
										{lang.text}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl variant="outlined">
							<InputLabel id="input-label">Media Type</InputLabel>
							<Select
								labelId="input-label"
								id="filter-dropdown"
								value={filters.mediaType}
								onChange={(e) =>
									onFilterChange(
										e.target.name,
										e.target.value
									)
								}
								label="Media Type"
								name="mediaType"
							>
								<MenuItem value={''}>All Media Types</MenuItem>
								{LIBRARY_MEDIA_TYPE.getAll().map((el) => (
									<MenuItem value={el.id}>{el.text}</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControl variant="outlined">
							<InputLabel id="input-label">
								Sadhna Group
							</InputLabel>
							<Select
								multiple
								labelId="input-label"
								id="filter-dropdown"
								value={filters.sadhnaGroup}
								onChange={(e) =>
									onFilterChange(
										e.target.name,
										e.target.value
									)
								}
								label="Sadhna Group"
								name="sadhnaGroup"
							>
								<MenuItem value={''}>
									All Sadhna Groups
								</MenuItem>
								{SADHNA_GROUP.getAll().map((el) => (
									<MenuItem value={el.id}>{el.text}</MenuItem>
								))}
							</Select>
						</FormControl>
						<IconButton
							onClick={() => setState({ modal: true })}
							color="primary"
							variant="contained"
						>
							Add
						</IconButton>
					</div>
				</div>
			</Paper>
			<div style={{ background: 'white' }}>
				<DataGrid
					rows={modifyProperty(data, '_id', 'id').map((el) => ({
						...el,
						mediaType: LIBRARY_MEDIA_TYPE.getById(el.mediaType)
							.text,
						language: LIBRARY_LANGUAGE.getById(el.lang).text,
						sadhnaGroup: el.sadhnaGroup
							.map((s) => SADHNA_GROUP.getById(s).text)
							.join(', '),
					}))}
					autoHeight
					columns={headCells.map((col) => ({
						...col,
						sortable: true,
						disableClickEventBubbling: true,
						...(isMobile
							? rowWidthConfig[col.field].mobile ||
							  rowWidthConfig[col.field].default
							: rowWidthConfig[col.field].default),
					}))}
					pageSize={filters.per_page}
					rowCount={filters.total_results}
					rowsPerPageOptions={[5, 10, 20, 50, 100]}
					pagination
					paginationMode="server"
					onPageChange={handleUserData}
					onPageSizeChange={onPageSizeChange}
					disableColumnFilter
					loading={loading}
				/>
			</div>
			<div className={classes.modalRoot}>
				<Modal
					open={!!modal}
					onClose={() => setState({ modal: null })}
					className={classes.modal}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div className={classes.paper}>
						<h1>Create Content</h1>
						<FormGroup row={1}>
							<TextField
								className="m-top-16"
								label="Title"
								variant="outlined"
								defaultValue={form.title}
								value={form.title}
								required
								type="string"
								name="title"
								onChange={(e) =>
									handleInput(e.target.name, e.target.value)
								}
								fullWidth
							/>
							<TextField
								className="m-top-16"
								label="URL"
								variant="outlined"
								defaultValue={form.url}
								required
								value={form.url}
								type="string"
								name="url"
								onChange={(e) =>
									handleInput(e.target.name, e.target.value)
								}
								fullWidth
							/>
							<FormControl
								variant="outlined"
								fullWidth
								className="m-top-16"
							>
								<InputLabel id="library-language">
									Language
								</InputLabel>
								<Select
									labelId="library-language"
									id="filter-dropdown"
									value={form.lang}
									required
									onChange={(e) =>
										handleInput(
											e.target.name,
											e.target.value
										)
									}
									label="Language"
									name="lang"
								>
									<MenuItem value={''}>All Language</MenuItem>
									{LIBRARY_LANGUAGE.getAll().map((lang) => (
										<MenuItem value={lang.id}>
											{lang.text}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							<FormControl
								variant="outlined"
								fullWidth
								className="m-top-16"
							>
								<InputLabel id="input-label">
									Media Type
								</InputLabel>
								<Select
									labelId="input-label"
									id="filter-dropdown"
									value={form.mediaType}
									required
									onChange={(e) =>
										handleInput(
											e.target.name,
											e.target.value
										)
									}
									label="Media Type"
									name="mediaType"
								>
									<MenuItem value={''}>
										All Media Types
									</MenuItem>
									{LIBRARY_MEDIA_TYPE.getAll().map((el) => (
										<MenuItem value={el.id}>
											{el.text}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							<FormControl
								variant="outlined"
								fullWidth
								className="m-top-16"
							>
								<InputLabel id="input-label">
									Sadhna Group
								</InputLabel>
								<Select
									multiple
									labelId="input-label"
									id="filter-dropdown"
									value={form.sadhnaGroup}
									required
									onChange={(e) =>
										handleInput(
											e.target.name,
											e.target.value
										)
									}
									label="Sadhna Group"
									name="sadhnaGroup"
								>
									<MenuItem value={''}>
										All Sadhna Groups
									</MenuItem>
									{SADHNA_GROUP.getAll().map((el) => (
										<MenuItem value={el.id}>
											{el.text}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</FormGroup>
						<div>
							<Button
								className="m-top-16"
								variant="contained"
								color="primary"
								startIcon={<CheckCircleRounded />}
								onClick={createAction}
								aria-label="Accept"
								style={{ marginRight: '12px' }}
							>
								Create
							</Button>
						</div>
					</div>
				</Modal>
			</div>
		</div>
	)
}

export default LibraryContainer
