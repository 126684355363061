import {
	Button,
	Card,
	IconButton,
	Modal,
	TextField,
	Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'
import {
	createOrUpdateAttendanceQRRequestAction,
	createOrUpdateInventoryRequestAction,
	getAttendanceQRsRequestAction,
} from '../../actions/event'
import { DataGrid, LoadIcon } from '@material-ui/data-grid'
import { isMobileScreen } from '../../utils'
import { CancelRounded, Done } from '@material-ui/icons'
import { closeDialog } from '../../actions'
import QRIcon from './assets/qr_code_2.svg'
import { getFullFormattedDateTimeString } from '../../components/Calender/helpers'

const useStyles = makeStyles({
	body: {
		flexDirection: 'column',
	},
	modalRoot: {
		// height: 300,
		position: 'absolute',
		flexGrow: 1,
		minWidth: 300,
		transform: 'translateZ(0)',
		// The position fixed scoping doesn't work in IE 11.
		// Disable this demo to preserve the others.
		'@media all and (-ms-high-contrast: none)': {
			display: 'none',
		},
	},
	modal: {
		display: 'flex',
		// padding: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: 400,
		backgroundColor: 'white',
		border: '2px solid #000',
		borderRadius: '8px',
		// boxShadow: theme.shadows[5],
		padding: '40px',
		lineHeight: '1.44',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	qrModalContainer: {
		width: '80vh',
		backgroundColor: 'white',
		border: '2px solid #000',
		borderRadius: '8px',
		// boxShadow: theme.shadows[5],
		padding: '40px',
		lineHeight: '1.44',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
})

const ManageQRScreen = ({ eventId }) => {
	const [filters, setFilters] = useState({
		per_page: 10,
		page: 1,
	})
	const [modalData, setModalData] = useState(null)
	const [qrUrlModal, showQRModal] = useState(null)
	const eventReducer = useSelector((state) => state.eventReducer)
	const loading = eventReducer.loading
	const attendanceQrData = eventReducer?.attendanceQrData
	const classes = useStyles()
	const dispatch = useDispatch()

	const loadInitialData = useCallback(() => {
		dispatch(
			getAttendanceQRsRequestAction({
				...filters,
				id: '',
				eventId,
				status: 1,
			})
		)
	}, [dispatch, filters, eventId])

	useEffect(() => {
		loadInitialData()
	}, [filters.page, filters.per_page, loadInitialData])

	const headCells = [
		{
			field: 'title',
			numeric: false,
			headerName: 'Title',
			sortable: false,
		},
		{
			field: 'status',
			numeric: false,
			headerName: 'Status',
			sortable: false,
		},
		{
			field: 'createdAt',
			numeric: false,
			headerName: 'Created At',
			sortable: false,
		},
		{
			field: 'id',
			headerName: 'Actions',
			sortable: false,
			filterable: false,
			renderCell: (params) => (
				<>
					<IconButton
						onClick={() => {
							showQRModal(params?.row)
						}}
						aria-label="Edit"
						color="primary"
						title="Edit"
					>
						<img src={QRIcon} alt="QR Icon" />
					</IconButton>
				</>
			),
		},
	]

	const rowWidthConfig = headCells.reduce((acc, value) => {
		acc[value.field] = { default: { flex: 0.15 }, mobile: { width: 150 } }
		return acc
	}, {})

	const handleUserData = ({ page, pageSize }) => {
		setFilters((f) => ({
			...f,
			page: page,
			per_page: pageSize,
		}))
	}

	const onPageSizeChange = ({ pageSize }) => {
		setFilters((f) => ({
			...f,
			page: 1,
			per_page: pageSize,
		}))
	}

	const onChange = (key, value) => {
		setModalData((prevValue) => ({
			...prevValue,
			[key]: value,
		}))
	}

	const validateData = (data) => {
		return data?.title
	}

	const submitRequest = (deleteId = false) => {
		if (!validateData(modalData) && !deleteId) return
		if (!deleteId) {
			const { id } = modalData
			modalData.eventId = eventId
			dispatch(
				createOrUpdateAttendanceQRRequestAction({
					id,
					data: modalData,
				})
			).then((resp) => {
				setModalData(null)
				loadInitialData()
			})
		} else {
			dispatch(closeDialog())
			console.log(deleteId)
			dispatch(
				createOrUpdateInventoryRequestAction({
					id: deleteId,
					attendanceQrData: { status: 0 },
				})
			).then((resp) => {
				setModalData(null)
				loadInitialData()
			})
		}
	}

	return (
		<Card className="p-3">
			<div className="screen overview-screen">
				<div className="filter-header">
					<Typography variant="h4">Attendance QR Code</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={() => setModalData({})}
					>
						Create QR
					</Button>
				</div>
				{!!modalData && (
					<div className={classes.modalRoot}>
						<Modal
							open={!!modalData}
							onClose={() => setModalData(null)}
							className={classes.modal}
							aria-labelledby="simple-modal-title"
							aria-describedby="simple-modal-description"
							onBackdropClick={() => setModalData(null)}
						>
							<div className={classes.paper}>
								<form noValidate autoComplete="off">
									<Typography variant="h3">
										{modalData?._id
											? 'Update Attendance QR'
											: 'Create Attendance QR'}
									</Typography>
									<TextField
										className="m-top-16"
										label="Title"
										variant="outlined"
										defaultValue={modalData.title}
										value={modalData.title}
										name="title"
										onChange={(e) =>
											onChange(
												e.target.name,
												e.target.value
											)
										}
										fullWidth
									/>
								</form>

								<div className="m-top-16">
									<Button
										variant="contained"
										color="secondary"
										startIcon={<CancelRounded />}
										onClick={() => setModalData(null)}
										aria-label="Reject"
									>
										Close
									</Button>
									&nbsp; &nbsp; &nbsp;
									{loading ? (
										<LoadIcon />
									) : (
										<Button
											variant="contained"
											color="primary"
											startIcon={<Done />}
											onClick={() => submitRequest()}
											aria-label="Reject"
										>
											{modalData?.id
												? 'Update'
												: 'Create'}
										</Button>
									)}
								</div>
							</div>
						</Modal>
					</div>
				)}
				{!!qrUrlModal && (
					<div className={classes.modalRoot}>
						<Modal
							open={!!qrUrlModal}
							onClose={() => showQRModal(null)}
							className={classes.modal}
							aria-labelledby="simple-modal-title"
							aria-describedby="simple-modal-description"
							onBackdropClick={() => showQRModal(null)}
						>
							<div className={classes.qrModalContainer}>
								<img
									src={qrUrlModal.url}
									alt="QR"
									style={{
										height: '70vh',
										width: '70vh',
									}}
								/>
								<p>{qrUrlModal.title}</p>
							</div>
						</Modal>
					</div>
				)}
				<div className="m-top-16">
					<DataGrid
						rows={
							attendanceQrData?.data?.map((inv) => ({
								id: inv._id,
								title: inv.title,
								status:
									inv.status === 1 ? 'Active' : 'Inactive',
								updatedAt: inv.updatedAt,
								url: inv.url,
								createdBy: inv.createdBy,
								createdAt: getFullFormattedDateTimeString(
									inv.createdAt
								),
								addedBy: inv.addedBy,
							})) || []
						}
						autoHeight
						columns={headCells?.map((col) => ({
							...col,
							sortable: true,
							disableClickEventBubbling: true,
							// // resizable: true,
							...(isMobileScreen()
								? rowWidthConfig[col.field].mobile ||
								  rowWidthConfig[col.field].default
								: rowWidthConfig[col.field].default),
						}))}
						pageSize={filters.per_page}
						rowCount={attendanceQrData?.total_results}
						rowsPerPageOptions={[5, 10, 20, 50, 100]}
						pagination
						paginationMode="server"
						onPageChange={handleUserData}
						disableColumnFilter
						loading={loading}
						onPageSizeChange={onPageSizeChange}
					/>
				</div>
			</div>
		</Card>
	)
}

export default ManageQRScreen
