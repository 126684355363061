import React, { Component } from 'react'
import './style.scss'
import {
	Button,
	Card,
	CardContent,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@material-ui/core'
import { getAreaDetails } from '../../services/area'
import { getSummary } from '../../services/dashboard'

class DashboardContainer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			fromDate: undefined,
			toDate: undefined,
			areas: [{ _id: '', name: 'All' }],
			areaId: '',
		}
	}

	componentDidMount() {
		this.getSummaryDetails()
		getAreaDetails({ per_page: 100 }).then((res) => {
			this.setState({
				areas: [
					{ _id: '', name: 'All' },
					...res.data.data.map((area) => ({
						_id: area._id,
						name: area.name,
					})),
				],
			})
			localStorage.setItem('AREAS', JSON.stringify(res.data.data))
		})
	}

	getSummaryDetails = async () => {
		const { areaId, fromDate, toDate } = this.state
		const summary = await getSummary({ areaId, fromDate, toDate })
		this.setState({
			totalMemberCount: summary?.data?.totalMemberCount,
			pendingMemberCount: summary?.data?.pendingMemberCount,
			nonCoastokMemberCount:
				summary?.data?.nonCoastokMemberCount -
				summary?.data?.pendingMemberCount,
			coastokMemberCount: summary?.data?.coastokMemberCount,
			frontLinerCount: summary?.data?.frontLinerCount,
			facilitatorCount: summary?.data?.facilitatorCount,
			totalQuestionCount: summary?.data?.totalQuestionCount,
			answeredQuestionCount: summary?.data?.answeredQuestionCount,
			unansweredQuestionCount: summary?.data?.unansweredQuestionCount,
			areaLeaderCount: summary?.data?.areaLeaderCount,
			assistantDirectorCount: summary?.data?.assistantDirectorCount,
			areaCount: summary?.data?.areaCount,
			incompleteBookingCount: summary?.data?.incompleteBookingCount,
			paymentCompleteBookingCount: summary?.data?.paymentCompleteBookingCount,
			paymentPendingBookingCount: summary?.data?.paymentPendingBookingCount,
			subsidyRequestedBookingCount: summary?.data?.subsidyRequestedBookingCount,
		})
	}

	handleChange = (name, e) => {
		this.setState({ [name]: e.target.value })
	}

	render() {
		const {
			fromDate,
			toDate,
			areaId,
			areas,
			totalMemberCount,
			pendingMemberCount,
			nonCoastokMemberCount,
			coastokMemberCount,
			frontLinerCount,
			facilitatorCount,
			totalQuestionCount,
			answeredQuestionCount,
			unansweredQuestionCount,
			areaLeaderCount,
			assistantDirectorCount,
			areaCount,
			incompleteBookingCount,
			paymentCompleteBookingCount,
			paymentPendingBookingCount,
			subsidyRequestedBookingCount,
		} = this.state
		return (
			<div className="dashboard-container">
				<h2 className="greeting">Welcome back,</h2>
				<div className="row">
					<div className="select-area">
						<InputLabel shrink id="demo-simple-select-label">
							Select Area
						</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={areaId}
							onChange={(e) => this.handleChange('areaId', e)}
							displayEmpty
							className="mb-16 w-full"
						>
							{areas.map((area) => (
								<MenuItem key={area._id} value={area._id}>
									{area.name}
								</MenuItem>
							))}
						</Select>
					</div>
					<TextField
						label="From Date"
						type="date"
						onChange={(e) => this.handleChange('fromDate', e)}
						value={fromDate}
						InputLabelProps={{
							shrink: true,
						}}
						className="mb-16"
					/>
					<TextField
						label="To Date"
						type="date"
						onChange={(e) => this.handleChange('toDate', e)}
						value={toDate}
						InputLabelProps={{
							shrink: true,
						}}
						className="mb-16"
					/>
					<Button
						variant="contained"
						onClick={this.getSummaryDetails}
						className="get-stats-btn"
					>
						Get Stats
					</Button>
				</div>
				<div>
					<div className="row summary-row">
						<SummaryCard
							text="Non-Coastok Members (rounds less than 4)"
							value={nonCoastokMemberCount}
						></SummaryCard>
						<SummaryCard
							text="Pending Members (rounds atleast 4)"
							value={pendingMemberCount}
						/>
						<SummaryCard
							text="Approved COASTOK Members"
							value={coastokMemberCount}
						/>
						<SummaryCard
							text="Total Users installed COASTOK App"
							value={totalMemberCount}
						/>
					</div>
					<div className="row summary-row">
						<SummaryCard
							text="Frontliners"
							value={frontLinerCount}
						/>
						<SummaryCard
							text="Facilitators"
							value={facilitatorCount}
						/>
						<SummaryCard
							text="Area Leaders assigned"
							value={areaLeaderCount}
						/>
						<SummaryCard
							text="Assistant Directors"
							value={assistantDirectorCount}
						/>
					</div>
					<div className="row summary-row">
						<SummaryCard text="Areas Covered" value={areaCount} />
						<SummaryCard
							text="Total Questions Asked"
							value={totalQuestionCount}
						/>
						<SummaryCard
							text="Total Queries Resolved"
							value={answeredQuestionCount}
						/>
						<SummaryCard
							text="Pending Questions"
							value={unansweredQuestionCount}
						/>
					</div>
					<div className="row summary-row">
						<SummaryCard text="Incomplete bookings" value={incompleteBookingCount} />
						<SummaryCard
							text="Payments pending"
							value={paymentPendingBookingCount}
						/>
						<SummaryCard
							text="Payments completed"
							value={paymentCompleteBookingCount}
						/>
						<SummaryCard
							text="Subsidy Requested"
							value={subsidyRequestedBookingCount}
						/>
					</div>
				</div>
			</div>
		)
	}
}

const SummaryCard = ({ text, value }) => {
	return (
		<Card className="summary-card">
			<CardContent>
				<Typography>{text}</Typography>
				<Typography variant="h3">{value}</Typography>
			</CardContent>
		</Card>
	)
}

export default DashboardContainer
