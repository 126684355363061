import React from 'react'
import { Button, Modal, Popover, Typography } from '@material-ui/core'
import { Edit, Check } from '@material-ui/icons'
import { getScheduleAction } from '../../actions/schedule'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import { withStyles } from '@material-ui/core/styles'
import { ROUTE } from '../../constants/route'
import { closeDialog, showDialog } from '../../actions'
import './style.scss'
import Calendar, { CalendarDayHeader } from '../../components/Calender'
import UpdateNotificationPopup from './UpdateNotificationModal'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'

const styles = {
	tableHeader: {
		padding: '20px',
		marginBottom: '20px',
	},
}

class ScheduleNotificationContainer extends React.Component {
	constructor(props) {
		super(props)
		const today = new Date()
		this.state = {
			yearAndMonth: [today.getFullYear(), today.getMonth() + 1],
		}
	}

	componentDidMount() {
		let params = {
			date: format(new Date(), 'MM/yy'),
		}
		this.props.getScheduleAction(params)
	}

	viewPledgeDrive = (row) => {
		const { history } = this.props
		history.push(`${ROUTE.PLEDGING.path}/${row._id}`)
	}

	editNotification = (row) => {
		this.setState({
			modalData: row,
			modalType: 'EDIT',
		})
	}

	createNotification = (selectedDate) => {
		const date = format(new Date(selectedDate.dateString), 'dd/MM/yy')
		this.setState({
			modalData: {
				date,
			},
			modalType: 'CREATE',
		})
	}

	deletePledgeDrive = () => {
		this.props.showDialog({
			title: 'Delete this Pledge Drive?',
			description:
				"Are you sure to delete this Pledge Drive? This action won't be reverted",
			actions: [
				{
					action: this.props.closeDialog,
					text: 'Cancel',
					color: 'secondary',
				},
				{ action: this.props.closeDialog, text: 'Yes, Delete Pledge' },
			],
		})
	}

	onModalClose = () => {
		this.setState({
			modalData: null,
		})
	}

	setYearAndMonth = (yearAndMonth) => {
		let { schedules } = this.props
		if (
			schedules.hasOwnProperty(
				(yearAndMonth[1] < 10
					? `0${yearAndMonth[1]}`
					: yearAndMonth[1]) +
					'/' +
					yearAndMonth[0].toString().slice(2)
			)
		) {
			this.setState({
				yearAndMonth,
			})
		} else {
			let params = {
				date:
					(yearAndMonth[1] < 10
						? `0${yearAndMonth[1]}`
						: yearAndMonth[1]) +
					'/' +
					yearAndMonth[0].toString().slice(2),
			}
			this.props.getScheduleAction(params).then(() => {
				this.setState({
					yearAndMonth,
				})
			})
		}
	}

	render() {
		const { schedules } = this.props
		const { modalData, modalType } = this.state
		return (
			<div className={`pledging-container`}>
				{/* {this.populateData(pledge.pledgeDrives.data)} */}
				{/* <Calendar defaultValue={new Date()} /> */}
				{
					<Calendar
						yearAndMonth={this.state.yearAndMonth}
						onYearAndMonthChange={this.setYearAndMonth}
						renderDay={(calendarDayObject) => {
							let dateObj = format(
								new Date(calendarDayObject.dateString),
								'MM/yy'
							)
							const fmtDate = format(
								new Date(calendarDayObject.dateString),
								'dd/MM/yy'
							)
							if (schedules.hasOwnProperty(dateObj)) {
								let schedule = schedules[dateObj]
								let todaySchedule
								try {
									// if date is in mm/dd/yy format
									todaySchedule = schedule.filter(
										(el) =>
											(el.date
												? format(
														new Date(el.date),
														'MM/dd/yy'
												  )
												: null) === fmtDate
									)
								} catch (e) {
									// if date is in dd/mm/yy format
									todaySchedule = schedule.filter(
										(el) => el.date === fmtDate
									)
								}

								return (
									<>
										<CalendarDayHeader
											calendarDayObject={
												calendarDayObject
											}
										/>
										<div className="scheduled-notification-container">
											{todaySchedule?.map(
												(todaySch, index) => (
													<PopupState
														key={index}
														variant="popover"
														popupId={
															'popover' +
															index +
															todaySch.date
														}
													>
														{(popupState) => (
															<>
																<div
																	onClick={(
																		ev
																	) => {
																		ev.stopPropagation()
																	}}
																	className={
																		todaySch.sent !==
																		1
																			? 'scheduled-item'
																			: 'notification-sent'
																	}
																>
																	<span
																		{...bindTrigger(
																			popupState
																		)}
																	>
																		{
																			todaySch.heading
																		}
																	</span>
																</div>
																<Popover
																	{...bindPopover(
																		popupState
																	)}
																	anchorOrigin={{
																		vertical:
																			'bottom',
																		horizontal:
																			'center',
																	}}
																	transformOrigin={{
																		vertical:
																			'top',
																		horizontal:
																			'center',
																	}}
																>
																	<div
																		style={{
																			padding: 15,
																			maxWidth: 300,
																		}}
																		onClick={(
																			event
																		) => {
																			event.stopPropagation()
																		}}
																	>
																		<h2
																			style={{
																				margin: '0px 0px 8px 0px',
																			}}
																		>
																			Date:{' '}
																			{
																				todaySch.date
																			}
																		</h2>
																		<Typography
																			gutterBottom
																		>
																			Title:{' '}
																			{
																				todaySch.heading
																			}
																		</Typography>
																		<Typography
																			gutterBottom
																		>
																			Body:{' '}
																			{
																				todaySch.body
																			}
																		</Typography>

																		<div
																			style={{
																				display:
																					'flex',
																				justifyContent:
																					'flex-end',
																				marginTop: 14,
																			}}
																		>
																			<Button
																				color="warning"
																				variant="contained"
																				className="mr-1em"
																				onClick={(
																					event
																				) => {
																					popupState.close()
																					event.stopPropagation()
																					this.editNotification(
																						todaySch
																					)
																				}}
																			>
																				<Edit />
																			</Button>
																			{/* <Button
																	color="warning"
																	variant="contained" 
																	onClick={(event)=> {popupState.close();event.stopPropagation();}}
																>
																	<Delete/>
																</Button> */}
																			<Button
																				color="warning"
																				variant="contained"
																				onClick={(
																					event
																				) => {
																					popupState.close()
																					event.stopPropagation()
																				}}
																			>
																				<Check />
																			</Button>
																		</div>
																	</div>
																</Popover>
															</>
														)}
													</PopupState>
												)
											)}
										</div>
									</>
								)
							} else {
								return (
									<div>
										<CalendarDayHeader
											calendarDayObject={
												calendarDayObject
											}
										/>
									</div>
								)
							}
						}}
						onClickDay={this.createNotification}
						dayLabel={() => <div />}
						schedules={schedules}
					/>
				}
				{modalData && (
					<Modal
						open={!!modalData}
						onClose={this.onModalClose}
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
					>
						{modalType === 'CREATE' ? (
							<div className="c-modal">
								<UpdateNotificationPopup
									notificationId={modalData._id}
									heading={modalData.heading}
									body={modalData.body}
									date={modalData.date}
									closeHandler={this.onModalClose}
								/>
							</div>
						) : (
							<div className="c-modal">
								<UpdateNotificationPopup
									notificationId={modalData._id}
									heading={modalData.heading}
									body={modalData.body}
									date={modalData.date}
									closeHandler={this.onModalClose}
								/>
							</div>
						)}
					</Modal>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	schedules: state.scheduleReducer.schedules,
	loading: state.scheduleReducer.loading,
})

const mapDispatchToProps = {
	getScheduleAction,
	showDialog,
	closeDialog,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(ScheduleNotificationContainer))
