import React, { useEffect } from 'react'
import confetti from './assets/confetti.mp4'
import reel from './assets/reel.mp4'
import './style.scss'
import {
	Slider2,
	glimpses,
} from '../EventRegister/particular-pages/Viplava2023'
import event06 from './assets/event06.jpg'
import { Button } from '@material-ui/core'

const Donate = () => {
	const donate = (amount, cause) => {
		//donate
	}

	useEffect(() => {
		const video = document.getElementById('myVideo')
		if (video) {
			const videoSource = video.getElementsByTagName('source')[0]
			const videoSourceSrc = videoSource.src
			const mediaFragment = videoSourceSrc.substr(
				videoSourceSrc.indexOf('#') + 1,
				videoSourceSrc.length
			)
			const mediaFragmentBound1 = parseInt(
				mediaFragment.substr(
					mediaFragment.indexOf('=') + 1,
					mediaFragment.indexOf(',')
				)
			)
			const mediaFragmentBound2 = parseInt(
				mediaFragment.substr(
					mediaFragment.indexOf(',') + 1,
					mediaFragment.length
				)
			)
			video.addEventListener('timeupdate', function () {
				checkMediaFragmentBounds(
					video,
					mediaFragmentBound1,
					mediaFragmentBound2
				)
			})
		}
		document.getElementsByTagName('html')[0].style.overflowY = 'scroll'
		document.getElementsByTagName('body')[0].style.position = 'absolute'
	}, [])

	function checkMediaFragmentBounds(video, x, y) {
		if (video.currentTime > y) {
			video.currentTime = x
			video.play()
		}
	}

	// return (
	// 	<div className="donate-container">
	// 		<div className="banner">
	// 			<div className="video">
	// 				<video width="100%" muted autoPlay loop id="myVideo">
	// 					<source
	// 						src={
	// 							window.innerWidth <= 720
	// 								? `${reel}#t=6,16`
	// 								: confetti
	// 						}
	// 						type="video/mp4"
	// 					/>
	// 				</video>
	// 			</div>
	// 			{window.innerWidth > 720 && (
	// 				<div className="glimpses">
	// 					<Slider2 slides={glimpses} />
	// 					<div className="message">
	// 						<h1>Viplava 2022 was a success!</h1>
	// 						<p>
	// 							Need your help to make Viplava 2023 successful
	// 							too.
	// 						</p>
	// 					</div>
	// 				</div>
	// 			)}
	// 			<div className="content">
	// 				<h1>Donate</h1>
	// 				<p>
	// 					Your kind donation can help hundreds of people to
	// 					participate in this maha yagya.
	// 				</p>
	// 			</div>
	// 		</div>
	// 		<div className="sponsor-section">
	// 			<h1>Devotee Sponsorship</h1>
	// 			<img src={event06} alt="sponsor" />
	// 			<table className="table">
	// 				<thead>
	// 					<tr>
	// 						<th>S.no</th>
	// 						<th>No. of Devotee </th>
	// 						<th>Amount </th>
	// 						<th>Token of Gratitude</th>
	// 					</tr>
	// 				</thead>
	// 				<tbody>
	// 					{' '}
	// 					<tr>
	// 						<td>1</td>
	// 						<td>4</td>
	// 						<td>6000</td>
	// 						<td>Gift</td>
	// 						<td>
	// 							<Button
	// 								onClick={() => {
	// 									donate(6000, 'devotee')
	// 								}}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 					<tr>
	// 						<td>2</td>
	// 						<td>10</td>
	// 						<td>15000</td>
	// 						<td>Gift</td>
	// 						<td>
	// 							<Button
	// 								onClick={() => {
	// 									donate(15000, 'devotee')
	// 								}}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 					<tr>
	// 						<td>3</td>
	// 						<td>30</td>
	// 						<td>45000</td>
	// 						<td>Gift</td>
	// 						<td>
	// 							<Button
	// 								onClick={() => {
	// 									donate(45000, 'devotee')
	// 								}}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 					<tr>
	// 						<td>4</td>
	// 						<td>50</td>
	// 						<td>75000</td>
	// 						<td>Staying Facilities ; Gift</td>
	// 						<td>
	// 							<Button
	// 								onClick={() => donate(75000, 'devotee')}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 					<tr>
	// 						<td>5</td>
	// 						<td>100</td>
	// 						<td>150000</td>
	// 						<td>Staying Facilities ; Gift on Stage </td>
	// 						<td>
	// 							<Button
	// 								onClick={() => donate(150000, 'devotee')}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 					<tr>
	// 						<td>6</td>
	// 						<td>200</td>
	// 						<td>300000</td>
	// 						<td>Staying Facilities ; Special Gift on Stage </td>
	// 						<td>
	// 							<Button
	// 								onClick={() => donate(300000, 'devotee')}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 				</tbody>
	// 			</table>
	// 		</div>
	// 		<div className="sponsor-section">
	// 			<h1>Dhoti Kurta Sponsorship</h1>
	// 			<img src={event06} alt="sponsor" />
	// 			<table className="table">
	// 				<thead>
	// 					<tr>
	// 						<th>S.no</th>
	// 						<th>No. of Devotee </th>
	// 						<th>Amount </th>
	// 						<th>Token of Gratitude</th>
	// 					</tr>
	// 				</thead>
	// 				<tbody>
	// 					{' '}
	// 					<tr>
	// 						<td>1</td>
	// 						<td>5</td>
	// 						<td>2000</td>
	// 						<td>Gift</td>
	// 						<td>
	// 							<Button
	// 								onClick={() => {
	// 									donate(2000, 'dhoti-kurta')
	// 								}}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 					<tr>
	// 						<td>2</td>
	// 						<td>25</td>
	// 						<td>10000</td>
	// 						<td>Gift</td>
	// 						<td>
	// 							<Button
	// 								onClick={() => {
	// 									donate(10000, 'dhoti-kurta')
	// 								}}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 					<tr>
	// 						<td>3</td>
	// 						<td>50</td>
	// 						<td>20000</td>
	// 						<td>Gift</td>
	// 						<td>
	// 							<Button
	// 								onClick={() => {
	// 									donate(20000, 'dhoti-kurta')
	// 								}}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 					<tr>
	// 						<td>4</td>
	// 						<td>100</td>
	// 						<td>40000</td>
	// 						<td>Staying Facilities ; Gift</td>
	// 						<td>
	// 							<Button
	// 								onClick={() => donate(40000, 'dhoti-kurta')}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 					<tr>
	// 						<td>5</td>
	// 						<td>250</td>
	// 						<td>100000</td>
	// 						<td>Staying Facilities ; Gift on Stage </td>
	// 						<td>
	// 							<Button
	// 								onClick={() =>
	// 									donate(100000, 'dhoti-kurta')
	// 								}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 					<tr>
	// 						<td>6</td>
	// 						<td>500</td>
	// 						<td>200000</td>
	// 						<td>Staying Facilities ; Special Gift on Stage </td>
	// 						<td>
	// 							<Button
	// 								onClick={() =>
	// 									donate(200000, 'dhoti-kurta')
	// 								}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 				</tbody>
	// 			</table>
	// 		</div>
	// 		<div className="sponsor-section">
	// 			<h1>Prasadam Sponsorship</h1>
	// 			<img src={event06} alt="sponsor" />
	// 			<table className="table">
	// 				<thead>
	// 					<tr>
	// 						<th>S.no</th>
	// 						<th>No. of Devotee </th>
	// 						<th>Amount </th>
	// 						<th>Token of Gratitude</th>
	// 					</tr>
	// 				</thead>
	// 				<tbody>
	// 					<tr>
	// 						<td>1</td>
	// 						<td>100</td>
	// 						<td>10000</td>
	// 						<td>Gift</td>
	// 						<td>
	// 							<Button
	// 								onClick={() => {
	// 									donate(10000, 'prasadam')
	// 								}}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 					<tr>
	// 						<td>2</td>
	// 						<td>250</td>
	// 						<td>25000</td>
	// 						<td>Gift</td>
	// 						<td>
	// 							<Button
	// 								onClick={() => {
	// 									donate(25000, 'prasadam')
	// 								}}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 					<tr>
	// 						<td>3</td>
	// 						<td>500</td>
	// 						<td>50000</td>
	// 						<td>Gift</td>
	// 						<td>
	// 							<Button
	// 								onClick={() => {
	// 									donate(50000, 'prasadam')
	// 								}}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 					<tr>
	// 						<td>4</td>
	// 						<td>1000</td>
	// 						<td>100000</td>
	// 						<td>Staying Facilities ; Gift</td>
	// 						<td>
	// 							<Button
	// 								onClick={() => donate(100000, 'prasadam')}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 					<tr>
	// 						<td>5</td>
	// 						<td>2500</td>
	// 						<td>250000</td>
	// 						<td>Staying Facilities ; Gift on Stage </td>
	// 						<td>
	// 							<Button
	// 								onClick={() => donate(250000, 'prasadam')}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 					<tr>
	// 						<td>6</td>
	// 						<td>5000</td>
	// 						<td>500000</td>
	// 						<td>Staying Facilities ; Special Gift on Stage </td>
	// 						<td>
	// 							<Button
	// 								onClick={() => donate(500000, 'prasadam')}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 					<tr>
	// 						<td>6</td>
	// 						<td>10000</td>
	// 						<td>1000000</td>
	// 						<td>
	// 							Guest of Honour ; Staying Facilities ; Special
	// 							Gift on Stage
	// 						</td>
	// 						<td>
	// 							<Button
	// 								onClick={() => donate(1000000, 'prasadam')}
	// 							>
	// 								Donate
	// 							</Button>
	// 						</td>
	// 					</tr>
	// 				</tbody>
	// 			</table>
	// 		</div>
	// 	</div>
	// )

	return (
		<div>
			<img
				alt="donation-img-1"
				src="https://res.cloudinary.com/dnuq1lgqs/image/upload/v1695664803/V23_Fund_Bifold_Brochure_1_-0_xcbpyj.png"
			/>
			<img
				alt="donation-img-2"
				src="https://res.cloudinary.com/dnuq1lgqs/image/upload/v1695664803/V23_Fund_Bifold_Brochure_1_-1_eymyng.png"
			/>
			<img
				alt="donation-img-3"
				src="https://res.cloudinary.com/dnuq1lgqs/image/upload/v1695664803/V23_Fund_Bifold_Brochure_1_-2_vubcmv.png"
			/>
			<img
				alt="donation-img-4"
				src="https://res.cloudinary.com/dnuq1lgqs/image/upload/v1695664800/V23_Fund_Bifold_Brochure_1_-3_phturg.png"
			/>
		</div>
	)
}

export default Donate
