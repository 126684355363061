import { useState, useRef, useEffect } from 'react'

function useCounter(dateString) {
	const [counter, setCounter] = useState({
		days: 0,
		hours: 0,
		min: 0,
		sec: 0,
	})
	const counterRef = useRef(null)

	useEffect(() => {
		const date = new Date(dateString)
		console.log(date)
		counterRef.current = setInterval(() => {
			const now = new Date()
			const diff = date - now + 24 * 60 * 60 * 1000
			if (typeof setCounter === 'function')
				setCounter((c) => ({
					days: Math.floor(diff / 1000 / 60 / 60 / 24),
					hours: Math.floor((diff / 1000 / 60 / 60) % 24),
					min: Math.floor((diff / 1000 / 60) % 60),
					sec: Math.floor((diff / 1000) % 60),
				}))
		}, 1000)
		return () => {
			clearInterval(counterRef.current)
		}
	}, [])

	return counter
}

export default useCounter
