import {
	Button,
	IconButton,
	Modal,
	Typography,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	TextField,
	InputAdornment,
	debounce,
} from '@material-ui/core'
import { DataGrid, SearchIcon } from '@material-ui/data-grid'
import {
	CancelRounded,
	CheckCircleRounded,
	CloudDownload,
	Visibility,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	approveSubsidyRequestAction,
	getFundBalanceAction,
	getSubsidyListAction,
	rejectSubsidyRequestAction,
} from '../../../actions/event'
import {
	applyFilter,
	exportFromJSON,
	getAreas,
	isAreaLeader,
	isGenSecretary,
	isMobileScreen,
} from '../../../utils'
import '../style.scss'
import { format } from 'date-fns'

const useStyles = makeStyles({
	root: {
		maxWidth: 345,
	},
	media: {
		height: 140,
	},

	modalRoot: {
		// height: 300,
		position: 'absolute',
		flexGrow: 1,
		minWidth: 300,
		transform: 'translateZ(0)',
		// The position fixed scoping doesn't work in IE 11.
		// Disable this demo to preserve the others.
		'@media all and (-ms-high-contrast: none)': {
			display: 'none',
		},
	},
	modal: {
		display: 'flex',
		// padding: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: 400,
		backgroundColor: 'white',
		border: '2px solid #000',
		borderRadius: '8px',
		// boxShadow: theme.shadows[5],
		padding: '40px',
		lineHeight: '1.44',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	reasonInput: {
		height: '88px',
		overflow: 'hidden',
		width: '100%',
		marginTop: '16px',
		padding: '12px',
	},
})

const SubsidyScreen = ({ eventId }) => {
	const [filters, setFilters] = useState({
		areaId: ['ALL'],
		page: 1,
		per_page: 10,
	})
	const [loading, setLoading] = useState(false)
	const [subsidyModal, setSubsidyModal] = useState(null)
	const [searchValue, setSearchValue] = useState('')

	const dispatch = useDispatch()
	const classes = useStyles()
	const eventReducer = useSelector((state) => state.eventReducer)
	const { subsidies, funds } = eventReducer
	const AREAS =
		localStorage.getItem('AREAS') &&
		JSON.parse(localStorage.getItem('AREAS'))

	const viewRequest = (value) => {
		setSubsidyModal(value)
	}

	const approveRequest = () => {
		dispatch(
			approveSubsidyRequestAction({
				id: subsidyModal._id,
				amountAfterSubsidy: subsidyModal.amountAfterSubsidy,
				comments: `Please pay ₹${subsidyModal.amountAfterSubsidy}`,
				subsidy:
					subsidyModal?.finalAmount -
					subsidyModal?.amountAfterSubsidy,
			})
		)
		setSubsidyModal(null)
	}
	const rejectRequest = () => {
		dispatch(
			rejectSubsidyRequestAction({
				id: subsidyModal._id,
				comments: `Unacceptable.`,
			})
		)
		setSubsidyModal(null)
	}

	const rowWidthConfig = {
		name: { default: { flex: 0.15 }, mobile: { width: 150 } },
		phone: { default: { flex: 0.1 }, mobile: { width: 150 } },
		email: { default: { flex: 0.2 }, mobile: { width: 200 } },
		facilitator: { default: { flex: 0.15 }, mobile: { width: 150 } },
		bookingReferenceNo: { default: { flex: 0.1 }, mobile: { width: 150 } },
		bookingDateTime: { default: { flex: 0.15 } },
		connectedArea: { default: { flex: 0.2 }, mobile: { width: 150 } },
		finalAmount: { default: { flex: 0.1 }, mobile: { width: 150 } },
		amountAfterSubsidy: { default: { flex: 0.15 }, mobile: { width: 100 } },
		user: { default: { width: 160 } },
	}

	const headCells = [
		{
			field: 'name',
			numeric: false,
			headerName: 'Name',
			sortable: false,
		},
		{
			field: 'phone',
			numeric: false,
			headerName: 'Phone',
			sortable: false,
		},
		{
			field: 'email',
			numeric: false,
			headerName: 'Email',
			sortable: false,
		},
		{
			field: 'facilitator',
			numeric: false,
			headerName: 'Facilitator',
			sortable: false,
		},
		{
			field: 'bookingReferenceNo',
			numeric: false,
			headerName: 'Booking Reference #',
			sortable: false,
		},
		{
			field: 'bookingDateTime',
			numeric: false,
			headerName: 'Booking Date & Time',
			sortable: false,
		},
		{
			field: 'finalAmount',
			numeric: false,
			headerName: 'Final Amount',
			sortable: true,
		},
		{
			field: 'amountAfterSubsidy',
			numeric: false,
			headerName: 'Amount after subsidy',
			sortable: true,
		},
		{
			field: 'connectedArea',
			numeric: false,
			headerName: 'Area',
			sortable: false,
		},
		{
			field: 'user',
			headerName: 'Actions',
			sortable: false,
			filterable: false,
			renderCell: (params) => (
				<strong>
					<IconButton
						onClick={() => viewRequest(params.row)}
						aria-label="View Reason"
						color="primary"
						title="View Reason"
					>
						<Visibility />
					</IconButton>
				</strong>
			),
		},
	]

	useEffect(() => {
		getSubsidyData(searchValue)
	}, [filters.areaId, filters.page, searchValue, filters.per_page])

	const getSubsidyData = useCallback(
		debounce((q, isDownload) => {
			setLoading(true)
			const areaId =
				filters.areaId[0] === 'ALL'
					? undefined
					: filters.areaId.join(',')

			if (isAreaLeader() || isGenSecretary()) {
				const extraParam = { per_page: 5000, page: 1 }
				dispatch(
					getSubsidyListAction({
						...filters,
						areaId,
						q,
						eventId,
						...(isDownload ? extraParam : {}),
					})
				).then((data) => {
					if (isDownload) {
						exportFromJSON({
							data: data?.data?.data,
							fileName: `Subsidy Data ${format(
								new Date(),
								'dd-MM-yyyy'
							)}`,
						})
					}
					setLoading(false)
				})
				dispatch(getFundBalanceAction(areaId))
			} else {
				dispatch(
					getSubsidyListAction({ ...filters, areaId, q, eventId })
				).then(() => {
					setLoading(false)
				})
			}
		}, 1000),
		[filters]
	)

	const handleUserData = ({ page, pageSize }) => {
		setFilters((f) => ({
			...f,
			page: page,
			per_page: pageSize,
		}))
	}

	const onPageSizeChange = ({ pageSize }) => {
		setFilters((f) => ({
			...f,
			page: 1,
			per_page: pageSize,
		}))
	}

	const onFilterChange = (name, value) => {
		let whatAdded, whatRemoved
		if (value.length > filters[name].length) {
			whatAdded = value.find((f) => !filters[name].includes(f))
		} else {
			whatRemoved = filters[name].find((f) => !value.includes(f))
		}
		if (typeof whatAdded !== undefined) {
			if (whatAdded === 'ALL') value = ['ALL']
			else value = value.filter((e) => e !== 'ALL')
		} else if (typeof whatRemoved !== undefined) {
			if (whatRemoved === 'ALL') value = value.length ? value : ['ALL']
		}

		setFilters((f) => ({
			...f,
			[name]: value,
		}))
	}

	const download = () => {
		getSubsidyData('', true)
	}

	return (
		<div className="screen subsidy-screen">
			{(isAreaLeader() || isGenSecretary()) && (
				<div className="fund-details justify-center items-center">
					<TextField
						id="input-with-icon-textfield"
						label="Search"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
						onChange={(e) => setSearchValue(e.target.value)}
						autoComplete="off"
						value={searchValue}
						className={'search-field'}
					/>
					{AREAS?.length > 1 && (
						<FormControl variant="outlined" className="area-input">
							<InputLabel id="demo-simple-select-outlined-label">
								Area
							</InputLabel>
							<Select
								multiple
								labelId="demo-simple-select-outlined-label"
								id="demo-simple-select-outlined"
								value={filters.areaId}
								onChange={(e) =>
									onFilterChange(
										e.target.name,
										e.target.value
									)
								}
								label="Area"
								name="areaId"
							>
								<MenuItem value={'ALL'}>All Areas</MenuItem>
								{getAreas().map((area, i) => (
									<MenuItem value={area._id} key={i}>
										{area.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					<div>Available Balance: {funds?.totalBalance}</div>
					<div>Balance on hold: {funds?.holdBalance}</div>
					<Button
						startIcon={<CloudDownload />}
						color="primary"
						variant="contained"
						onClick={download}
						style={{ marginBottom: '20px' }}
					>
						Download complete list
					</Button>
				</div>
			)}
			<div className={classes.modalRoot}>
				<Modal
					open={!!subsidyModal}
					onClose={() => setSubsidyModal(null)}
					className={classes.modal}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div className={classes.paper}>
						<Typography
							variant="body2"
							style={{
								textAlign: 'center',
								fontWeight: 'bold',
							}}
						>
							Request raised by
						</Typography>
						<Typography
							variant="body"
							style={{
								marginBottom: '20px',
								textAlign: 'center',
							}}
						>
							{subsidyModal?.userId?.initiatedName ||
								subsidyModal?.userId?.name}
						</Typography>
						<Typography
							variant="body2"
							style={{
								textAlign: 'center',
								fontWeight: 'bold',
							}}
						>
							Subsidy Amount
						</Typography>
						<Typography
							variant="body"
							style={{
								marginBottom: '20px',
								textAlign: 'center',
							}}
						>
							₹
							{subsidyModal?.totalAmount -
								subsidyModal?.amountAfterSubsidy}
						</Typography>
						{subsidyModal?.status === 2 && (
							<Typography
								variant="body2"
								style={{
									textAlign: 'center',
									fontWeight: 'bold',
								}}
							>
								Amount to be paid by{' '}
								{subsidyModal?.userId?.initiatedName ||
									subsidyModal?.userId?.name}{' '}
								after approval
							</Typography>
						)}
						{subsidyModal?.status === 13 && (
							<Typography
								variant="body2"
								style={{
									textAlign: 'center',
									fontWeight: 'bold',
								}}
							>
								Amount already paid by{' '}
								{subsidyModal?.userId?.initiatedName ||
									subsidyModal?.userId?.name}{' '}
								online
							</Typography>
						)}
						<Typography
							variant="body"
							style={{
								marginBottom: '20px',
								textAlign: 'center',
							}}
						>
							₹{subsidyModal?.amountAfterSubsidy}
						</Typography>
						<Typography
							variant="body2"
							style={{
								textAlign: 'center',
								fontWeight: 'bold',
							}}
						>
							Reason
						</Typography>
						<Typography
							variant="body"
							style={{
								marginBottom: '20px',
								textAlign: 'center',
							}}
						>
							{subsidyModal?.subsidyReason}
						</Typography>
						<div>
							<Button
								variant="contained"
								color="primary"
								startIcon={<CheckCircleRounded />}
								onClick={approveRequest}
								aria-label="Accept"
								style={{ marginRight: '12px' }}
							>
								Accept
							</Button>
							<Button
								variant="contained"
								startIcon={<CancelRounded />}
								onClick={rejectRequest}
								aria-label="Reject"
							>
								Reject
							</Button>
						</div>
					</div>
				</Modal>
			</div>
			<DataGrid
				rows={
					subsidies?.data?.map((s) => ({
						...s,
						id: s._id,
						name: s.userId.initiatedName || s.userId.name,
						email: s.userId.email,
						phone: s.userId.phone,
						facilitator:
							s.userId.facilitator?.initiatedName ||
							s.userId.facilitator?.name,
						connectedArea: s.connectedAreaId.name,
					})) || []
				}
				autoHeight
				columns={headCells.map((col) => ({
					...col,
					sortable: true,
					disableClickEventBubbling: true,
					// resizable: true,
					...(isMobileScreen()
						? rowWidthConfig[col.field].mobile ||
						  rowWidthConfig[col.field].default
						: rowWidthConfig[col.field].default),
				}))}
				pageSize={filters.per_page}
				rowCount={subsidies?.total_results}
				// rowCount={10}
				rowsPerPageOptions={[5, 10, 20, 50, 100]}
				pagination
				paginationMode="server"
				onPageChange={handleUserData}
				disableColumnFilter
				loading={loading}
				onPageSizeChange={onPageSizeChange}
			/>
		</div>
	)
}

export default SubsidyScreen
