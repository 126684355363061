import {
	IconButton,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	Typography,
	Button,
	TextField,
	InputAdornment,
	debounce,
	Tooltip,
} from '@material-ui/core'
import { DataGrid, SearchIcon } from '@material-ui/data-grid'
import {
	CancelRounded,
	CheckCircleRounded,
	CloudDownload,
	MeetingRoomRounded,
} from '@material-ui/icons'
import { format } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeDialog, showDialog } from '../../../actions'
import {
	approveCashRequestAction,
	getFundBalanceAction,
	getCashListAction,
} from '../../../actions/event'
import { ACCOMODATION_CATEGORY, EVENT_BOOKING_STATUS } from '../../../constants'
import { HIDE_MAIN_LOADER, SHOW_MAIN_LOADER } from '../../../constants/actions'
import { getCashList } from '../../../services/event'
import { updateBooking } from '../../../services/viplava'
import {
	exportFromJSON,
	getAreas,
	isAreaLeader,
	isMobileScreen,
} from '../../../utils'
import '../style.scss'
import { getFinalAmount } from '../../Viplava/utils'

const CashScreen = ({ eventId }) => {
	const [filters, setFilters] = useState({
		areaId: ['ALL'],
		accomodation: ['ALL'],
		transportation: ['ALL'],
		status: ['ALL'],
		page: 1,
		per_page: 10,
	})
	const [loading, setLoading] = useState(false)
	const [searchValue, setSearchValue] = useState('')

	const dispatch = useDispatch()
	const eventReducer = useSelector((state) => state.eventReducer)
	const { cashRequests } = eventReducer
	const AREAS =
		localStorage.getItem('AREAS') &&
		JSON.parse(localStorage.getItem('AREAS'))
	const [areaId, setAreaId] = useState(AREAS?.[0]._id)

	const approveRequest = (data) => {
		if (data._id) {
			dispatch(
				showDialog({
					title: 'Approve this cash request?',
					description:
						"Are you sure to approve this cash request? This action won't be reverted",
					form: [
						{
							name: 'amount',
							label: 'Amount Received',
							placeholder: 'Input the Amount you recieved',
							defaultValue: data.tokenAmount,
						},
					],
					actions: [
						{
							action: dispatch(closeDialog()),
							text: 'Cancel',
							color: 'secondary',
						},
						{
							action: (dialogValue) => {
								dispatch(
									approveCashRequestAction({
										id: data._id,
										cashRecieved: dialogValue.amount,
									})
								)
								dispatch(closeDialog())
							},
							text: 'Yes, Approve request',
						},
					],
				})
			)
		}
	}

	const disproveRequest = (data) => {
		if (data._id) {
			dispatch(
				showDialog({
					title: 'Cancel this cash request?',
					description:
						"Are you sure to Cancel this cash request? This action won't be reverted",
					actions: [
						{
							action: dispatch(closeDialog()),
							text: 'Cancel',
							color: 'secondary',
						},
						{
							action: () => {
								cancelBooking(data)
								dispatch(closeDialog())
							},
							text: 'Yes, Cancel request',
						},
					],
				})
			)
		}
	}

	const rowWidthConfig = {
		name: { default: { flex: 0.15 }, mobile: { width: 150 } },
		phone: { default: { flex: 0.1 }, mobile: { width: 150 } },
		facilitator: { default: { flex: 0.15 }, mobile: { width: 150 } },
		transportation: {
			default: { flex: 0.1 },
			mobile: { width: 150 },
		},
		accomodationCategory: { default: { flex: 0.15 } },
		connectedArea: { default: { flex: 0.2 }, mobile: { width: 150 } },
		tokenAmount: { default: { flex: 0.1 }, mobile: { width: 100 } },
		finalAmount: { default: { flex: 0.1 }, mobile: { width: 100 } },
		paidAmount: { default: { flex: 0.1 }, mobile: { width: 100 } },
		status: { default: { flex: 0.1 }, mobile: { width: 100 } },
		subsidy: { default: { flex: 0.1 }, mobile: { width: 100 } },
		status_detailed: { default: { flex: 0.2 }, mobile: { width: 100 } },
		user: { default: { width: 160 } },
	}

	const headCells = [
		{
			field: 'name',
			numeric: false,
			headerName: 'Name',
			sortable: false,
		},
		{
			field: 'phone',
			numeric: false,
			headerName: 'Phone',
			sortable: false,
		},
		{
			field: 'facilitator',
			numeric: false,
			headerName: 'Facilitator',
			sortable: false,
		},
		{
			field: 'transportation',
			numeric: false,
			headerName: 'Transportation',
			sortable: false,
		},
		{
			field: 'accomodationCategory',
			numeric: false,
			headerName: 'Accomodation',
			sortable: false,
		},
		{
			field: 'finalAmount',
			numeric: false,
			headerName: 'Total Amount',
			sortable: true,
			renderCell: (params) => {
				return (
					<Tooltip
						title={getFinalAmount(params.row).join(',')}
						placement="top"
						arrow
					>
						<p>{params.value}</p>
					</Tooltip>
				)
			},
		},
		// {
		// 	field: 'tokenAmount',
		// 	numeric: false,
		// 	headerName: 'Token Amount',
		// 	sortable: true,
		// },
		{
			field: 'paidAmount',
			numeric: false,
			headerName: 'Amount paid',
			sortable: true,
		},
		{
			field: 'subsidy',
			numeric: false,
			headerName: 'Discount',
			sortable: false,
		},
		{
			field: 'status',
			numeric: false,
			headerName: 'Pay mode',
			sortable: true,
		},
		{
			field: 'status_detailed',
			numeric: false,
			headerName: 'Status',
			sortable: true,
		},
		{
			field: 'connectedArea',
			numeric: false,
			headerName: 'Area',
			sortable: false,
		},
		{
			field: 'user',
			headerName: 'Actions',
			sortable: false,
			filterable: false,
			renderCell: (params) => (
				<strong>
					{!isAreaLeader() && (
						<IconButton
							onClick={() => approveRequest(params.row)}
							aria-label="View Reason"
							color="primary"
							title="View Reason"
						>
							<CheckCircleRounded />
						</IconButton>
					)}
					{params.row.accomodationCategory === 'Dormitory' &&
						params.row.status === 'Cash' && (
							<IconButton
								onClick={() => upgradeToRoom(params.row)}
								aria-label="Upgrade to Room"
								color="primary"
								title="Upgrade to Room"
							>
								<MeetingRoomRounded />
							</IconButton>
						)}
					{params.row.status === 'Cash' && (
						<IconButton
							onClick={() => disproveRequest(params.row)}
							aria-label="Delete Booking"
							color="primary"
							title="Delete Booking"
						>
							<CancelRounded />
						</IconButton>
					)}
				</strong>
			),
		},
	]

	useEffect(() => {
		getCashData(searchValue)
	}, [filters, areaId, searchValue])

	const download = () => {
		const todayDate = format(new Date(), 'dd-MM-yyyy')
		const fileName = 'Cash Requests ' + todayDate
		const filterAreaId =
			filters.areaId[0] === 'ALL' ? undefined : filters.areaId.join(',')
		dispatch({ type: SHOW_MAIN_LOADER })
		getCashList({
			page: 1,
			per_page: 5000,
			eventId,
			areaId: filterAreaId,
			status:
				filters.status.join(',') === 'ALL' || !filters.status.join(',')
					? '9,11,12'
					: filters.status.join(','),
		})
			.then((res) => {
				exportFromJSON({
					data: res.data?.data?.map((s) => ({
						name: s.userId?.initiatedName || s.userId?.name,
						email: s.userId?.email,
						phone: s.userId?.phone,
						facilitator:
							s.userId?.facilitator?.initiatedName ||
							s.userId?.facilitator?.name,
						connectedArea: s.connectedAreaId?.name,
						transportationAmount: s.transportationAmount,
						transportation:
							s.transportationAmount > 0 ? 'Yes' : 'No',
						accomodationCategory:
							ACCOMODATION_CATEGORY[s.accomodationCategory]?.name,
						status: EVENT_BOOKING_STATUS[s.status]?.includes('CASH')
							? 'Cash'
							: 'Online',
						status_detailed: EVENT_BOOKING_STATUS[s.status],
						totalAmount: s.finalAmount,
						// tokenAmount: s.tokenAmount,
						amountPaid: s.paidAmount,
						discount: s.subsidy,
					})),
					fileName,
				})
				dispatch({ type: HIDE_MAIN_LOADER })
			})
			.catch((e) => {
				dispatch({ type: HIDE_MAIN_LOADER })
				console.log(e)
			})
	}

	const upgradeToRoom = async (rowData) => {
		const booking = cashRequests.data.find(
			(req) => req.bookingReferenceNo === rowData.bookingReferenceNo
		)
		await updateBooking({
			...booking,
			eventId,
			userId: booking.userId._id,
			accomodationCategory: ACCOMODATION_CATEGORY.ROOM.id,
			transportationAmount: booking.transportationAmount,
			paymentMode: 'CASH',
			tokenAmount: booking.tokenAmount + 500 || 500,
		})
		getCashData()
	}

	const cancelBooking = async (rowData) => {
		const booking = cashRequests.data.find(
			(req) => req.bookingReferenceNo === rowData.bookingReferenceNo
		)

		await updateBooking({
			...booking,
			eventId,
			userId: booking.userId._id,
			accomodationCategory: booking.accomodationCategory,
			transportationAmount: booking.transportationAmount,
			tokenAmount: booking.tokenAmount || 500,
		})
		getCashData()
	}

	const getCashData = useCallback(
		debounce((q) => {
			setLoading(true)
			const filterAreaId =
				filters.areaId[0] === 'ALL'
					? undefined
					: filters.areaId.join(',')
			const accomodation =
				filters.accomodation[0] === 'ALL'
					? undefined
					: filters.accomodation.join(',')
			const transportation =
				filters.transportation[0] === 'ALL'
					? undefined
					: filters.transportation.join(',')
			const status =
				filters.status[0] === 'ALL' || filters.status.length === 0
					? '9,11,12'
					: filters.status.join(',')

			if (isAreaLeader()) {
				dispatch(
					getCashListAction({
						...filters,
						areaId,
						eventId,
						status,
						accomodation,
						transportation,
						q,
					})
				).then(() => {
					setLoading(false)
				})
				dispatch(getFundBalanceAction(areaId))
			} else {
				dispatch(
					getCashListAction({
						...filters,
						eventId,
						areaId: filterAreaId,
						status,
						accomodation,
						transportation,
						q,
					})
				).then(() => {
					setLoading(false)
				})
			}
		}, 1000),
		[filters, areaId]
	)

	const handleUserData = ({ page, pageSize }) => {
		setFilters((f) => ({
			...f,
			page: page,
			per_page: pageSize,
		}))
	}

	const onPageSizeChange = ({ pageSize }) => {
		setFilters((f) => ({
			...f,
			page: 1,
			per_page: pageSize,
		}))
	}

	const onFilterChange = (name, value) => {
		let whatAdded, whatRemoved
		if (value.length > filters[name].length) {
			whatAdded = value.find((f) => !filters[name].includes(f))
		} else {
			whatRemoved = filters[name].find((f) => !value.includes(f))
		}
		if (typeof whatAdded !== undefined) {
			if (whatAdded === 'ALL') value = ['ALL']
			else value = value.filter((e) => e !== 'ALL')
		} else if (typeof whatRemoved !== undefined) {
			if (whatRemoved === 'ALL') value = value.length ? value : ['ALL']
		}
		setFilters((f) => ({
			...f,
			[name]: value,
			page: 1,
		}))
	}

	return (
		<div className="screen subsidy-screen">
			{isAreaLeader() && (
				<div className="fund-details">
					{AREAS?.length > 1 && (
						<FormControl variant="outlined">
							<InputLabel id="fixed-width-dropdown">
								Select Area
							</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={areaId}
								onChange={(e) => {
									setAreaId(e.target.value)
								}}
								displayEmpty
								className="mb-16 w-full"
							>
								{AREAS.map((area) => (
									<MenuItem key={area._id} value={area._id}>
										{area.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</div>
			)}
			<div className="filter-header">
				<Typography variant="h4">Cash Requests </Typography>
				<Button
					startIcon={<CloudDownload />}
					color="primary"
					variant="contained"
					onClick={download}
					style={{ marginBottom: '20px' }}
				>
					Download
				</Button>
			</div>
			<div className="filter-header">
				<TextField
					id="input-with-icon-textfield"
					label="Search"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
					onChange={(e) => setSearchValue(e.target.value)}
					autoComplete="off"
					value={searchValue}
					className={'search-field'}
				/>
				<FormControl variant="outlined">
					<InputLabel id="demo-simple-select-outlined-label">
						Area
					</InputLabel>
					<Select
						multiple
						labelId="demo-simple-select-outlined-label"
						id="fixed-width-dropdown"
						value={filters.areaId}
						onChange={(e) =>
							onFilterChange(e.target.name, e.target.value)
						}
						label="Area"
						name="areaId"
					>
						<MenuItem value={'ALL'}>All Areas</MenuItem>
						{getAreas().map((area, i) => (
							<MenuItem value={area._id} key={i}>
								{area.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl variant="outlined">
					<InputLabel id="demo-simple-select-outlined-label">
						Status
					</InputLabel>
					<Select
						multiple
						labelId="demo-simple-select-outlined-label"
						id="fixed-width-dropdown"
						value={filters.status}
						onChange={(e) =>
							onFilterChange(e.target.name, e.target.value)
						}
						label="Status"
						name="status"
					>
						<MenuItem value={'ALL'}>All Status</MenuItem>
						{Object.keys(EVENT_BOOKING_STATUS)
							.filter((el) =>
								['9', '12', '11', '3'].includes(String(el))
							)
							.map((statusKey) => (
								<MenuItem value={statusKey}>
									{EVENT_BOOKING_STATUS[statusKey]}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<FormControl variant="outlined">
					<InputLabel id="demo-simple-select-outlined-label">
						Accomodation
					</InputLabel>
					<Select
						multiple
						labelId="demo-simple-select-outlined-label"
						id="fixed-width-dropdown"
						value={filters.accomodation}
						onChange={(e) =>
							onFilterChange(e.target.name, e.target.value)
						}
						label="Accomodation"
						name="accomodation"
					>
						<MenuItem value={'ALL'}>
							All Accomodation Types
						</MenuItem>
						{[
							ACCOMODATION_CATEGORY.DORMATORY,
							ACCOMODATION_CATEGORY.ROOM,
							ACCOMODATION_CATEGORY.PREMIUM,
						].map((acc, i) => (
							<MenuItem value={acc.id} key={i}>
								{acc.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl variant="outlined">
					<InputLabel id="demo-simple-select-outlined-label">
						Transportation
					</InputLabel>
					<Select
						multiple
						labelId="demo-simple-select-outlined-label"
						id="fixed-width-dropdown"
						value={filters.transportation}
						onChange={(e) =>
							onFilterChange(e.target.name, e.target.value)
						}
						label="Transportation"
						name="transportation"
					>
						<MenuItem value={'ALL'}>Both Yes and No</MenuItem>
						<MenuItem value={true}>Yes</MenuItem>
						<MenuItem value={false}>No</MenuItem>
					</Select>
				</FormControl>
			</div>
			<DataGrid
				rows={
					cashRequests?.data?.map((s) => ({
						...s,
						id: s._id,
						name: s.userId.initiatedName || s.userId.name,
						email: s.userId.email,
						phone: s.userId.phone,
						facilitator:
							s.userId.facilitator?.initiatedName ||
							s.userId.facilitator?.name,
						connectedArea: s.connectedAreaId.name,
						transportationAmount: s.transportationAmount,
						transportation:
							s.transportationAmount > 0 ? 'Yes' : 'No',
						accomodationCategory:
							ACCOMODATION_CATEGORY[s.accomodationCategory]?.name,
						status: EVENT_BOOKING_STATUS[s.status].includes('CASH')
							? 'Cash'
							: 'Online',
						status_detailed: EVENT_BOOKING_STATUS[s.status],
						paidAmount: s.paidAmount,
						subsidy: s.subsidy,
					})) || []
				}
				autoHeight
				columns={headCells.map((col) => ({
					...col,
					sortable: true,
					disableClickEventBubbling: true,
					// resizable: true,
					...(isMobileScreen()
						? rowWidthConfig[col.field].mobile ||
						  rowWidthConfig[col.field].default
						: rowWidthConfig[col.field].default),
				}))}
				pageSize={filters.per_page}
				rowCount={cashRequests?.total_results}
				// rowCount={10}
				rowsPerPageOptions={[5, 10, 20, 50, 100]}
				pagination
				paginationMode="server"
				onPageChange={handleUserData}
				disableColumnFilter
				loading={loading}
				onPageSizeChange={onPageSizeChange}
			/>
		</div>
	)
}

export default CashScreen
