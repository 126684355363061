import React, { Component, Fragment } from "react";
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	Modal,
	Fade,
	TextField,
	Backdrop,
	Typography,
	CardHeader,
	CardContent,
	Card,
	CardActions,
} from "@material-ui/core";
import Recorder from "recorder-js";
import { Pagination } from "@material-ui/lab";
import { QUESTION_CATEGORY } from "../../constants";
import { getQuestions, updateAnswer } from "../../services/answer-question";
import "./style.scss";
import { uploadBlobFile } from "../../utils";
import { format } from "date-fns";
import AnswerQuestionModal from "./AnswerQuestionModal";
import ViewThreadModal from "./ViewThreadModal";

class AnswerQuestionContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			questions: [],
			per_page: 10,
			answer: 2,
			page: 1,
			totalResults: 0,
			modalOpen: false,
			isRecording: false,
			isRecorded: false,
			ansText: "",
			ansAudio: "",
			viewThread: true,
		};
	}
	componentDidMount() {
		const { per_page, page, answer } = this.state;
		// const answer = (answer === 'RESOLVED' ? 1 : (answer === 'PENDING' ? 0 : undefined));
		getQuestions({
			per_page,
			page,
			answer,
		}).then((res) => {
			this.setState({
				questions: res.data.data,
				totalResults: res.data.total_results,
			});
		});
	}

	startRecording = () => {
		this.setState({ isRecording: true, isRecorded: false });
	};

	stopRecording = () => {
		this.setState({ isRecording: false });
	};

	onStop = (recordedBlob) => {
		this.blob = recordedBlob;
		this.setState({ isRecorded: true });
	};

	resetRecording = () => {
		this.blob = null;
		this.setState({
			isRecorded: false,
			isRecording: false,
		});
	};

	toggleViewThread = () => {
		this.setState({
			viewThread: !this.state.viewThread,
		});
	};

	submitAnswer = (id) => {
		const { ansText } = this.state;
		const userId = JSON.parse(localStorage.getItem("UserProfile"))._id;
		if (this.blob) {
			uploadBlobFile(this.blob.blob).then((res) => {
				updateAnswer(id, {
					ansText,
					ansAudio: res.data.path,
					ansBy: userId,
				}).then((response) => {
					this.blob = null;
					this.setState({
						isRecorded: false,
						modalOpen: false,
					});
				});
			});
		} else {
			updateAnswer(id, {
				ansText,
				ansBy: userId,
			}).then((response) => {
				this.blob = null;
				this.setState({
					isRecorded: false,
					modalOpen: false,
				});
			});
		}
	};

	handleChange = (name, value) => {
		const { per_page, answer, page } = this.state;
		getQuestions({
			per_page,
			answer,
			page: name !== "page" ? 1 : page,
			[name]: value,
		}).then((res) => {
			this.setState({
				questions: res.data.data,
				totalResults: res.data.total_results,
				[name]: value,
			});
		});
	};

	toggleModalOpen = (question) => {
		this.setState({
			modalOpen: !this.state.modalOpen,
			question,
		});
	};

	render() {
		const { questions, per_page, answer, totalResults, modalOpen, viewThread } =
			this.state;

		return (
			<div className="answer-ques-container">
				<header className="page-header">
					<h2 className="page-title">Answer Question</h2>
					<div className="filters">
						<FormControl className="form-control">
							<InputLabel id="answer">Answer Status</InputLabel>
							<Select
								labelId="answer"
								value={answer}
								name="answer"
								onChange={(e) =>
									this.handleChange(e.target.name, e.target.value)
								}
							>
								{["PENDING", "RESOLVED", "ALL"].map((status, index) => (
									<MenuItem value={index}>{status}</MenuItem>
								))}
							</Select>
						</FormControl>
						<Pagination
							count={Math.ceil(totalResults / per_page)}
							variant="outlined"
							onChange={(e, page) => {
								this.handleChange("page", page);
							}}
						/>
						<FormControl className="form-control">
							<InputLabel id="per_page">Per Page</InputLabel>
							<Select
								labelId="per_page"
								value={per_page}
								name="per_page"
								onChange={(e) =>
									this.handleChange(e.target.name, e.target.value)
								}
							>
								{[5, 10, 20].map((per_page) => (
									<MenuItem value={per_page}>{per_page}</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</header>
				<ul className="masonry-with-columns">
					{questions &&
						questions.map((question) => {
							const askTime = new Date(question.askTime);
							const ansTime = new Date(question.ansTime);
							return (
								<div
									className={`question-card ${
										!question.discussion[0] ? "red" : "green"
									}`}
									key={question._id}
								>
									<div className="labels">
										<span className="label yellow">
											{QUESTION_CATEGORY[question.category]}
										</span>
										<span
											className={`label ${
												!question.discussion[0] ? "yellow" : "green"
											}`}
										>
											{!question.discussion[0] ? "PENDING" : "RESOLVED"}
										</span>
									</div>
									{question.askText && (
										<div className="ask-text">{question.askText}</div>
									)}
									{question.askAudio && (
										<audio
											className="ask-audio"
											src={question.askAudio}
											controls
										/>
									)}
									<div className="card-footer">
										<div className="asked-by">
											Asked by {question.askName} on {askTime.toDateString()}
											{question.discussion[0] && (
												<div className="asked-by">
													Answered by {question.discussion[0].ansName} on{" "}
													{format(
														new Date(question.discussion[0]?.ansTime),
														"dd MMM yyyy"
													)}
												</div>
											)}
										</div>
										<Fragment>
											<Button
												onClick={() => this.toggleModalOpen(question)}
												variant="contained"
												disableElevation
												className="view-thread-btn"
											>
												View thread
											</Button>
										</Fragment>
									</div>
								</div>
							);
						})}
				</ul>
				{modalOpen &&
					(viewThread ? (
						<ViewThreadModal
							question={this.state.question}
							onClose={this.toggleModalOpen}
							modalOpen={modalOpen}
							toggleViewThread={this.toggleViewThread}
						/>
					) : (
						<AnswerQuestionModal
							question={this.state.question}
							onClose={this.toggleModalOpen}
							modalOpen={modalOpen}
							toggleViewThread={this.toggleViewThread}
						/>
					))}
			</div>
		);
	}
}

export default AnswerQuestionContainer;
