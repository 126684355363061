import React from 'react'
import TabHeader from './components/TabHeader'
import './style.scss'

const CreateEventContainer = ({ match }) => {
	const [value, setValue] = React.useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<div className="event-container">
			<TabHeader value={value} handleChange={handleChange} />
			<div>
				{/* {value === 0 && <OverviewScreen eventId={eventId} />}
				{value === 1 && <SubsidyScreen eventId={eventId} />}
				{value === 2 && <CashScreen eventId={eventId} />}
				{value === 3 && <ReportScreen eventId={eventId} />}
				{value === 4 && <FundScreen eventId={eventId} />} */}
				Create Event
			</div>
		</div>
	)
}

export default CreateEventContainer
