import { Paper, Tab, Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { isGenSecretary } from '../../../utils'

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
	},
})

export const TAB_HEADER_VALUES = {
	OVERVIEW: 'overview',
	SUBSIDY_REQUESTS: 'subsidy_requests',
	CASH_REQUESTS: 'cash_requests',
	REPORTS: 'reports',
	FUNDS_OVERVIEW: 'funds_overview',
	MANAGE_INVENTORY: 'manage_inventory',
	MANAGE_QR: 'manage_qr',
}

const TabHeader = ({ value, handleChange }) => {
	const classes = useStyles()

	return (
		<Paper className={classes.root}>
			<Tabs
				value={value}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
			>
				<Tab label="Overview" value={TAB_HEADER_VALUES.OVERVIEW} />
				<Tab
					label="Subsidy Requests"
					value={TAB_HEADER_VALUES.SUBSIDY_REQUESTS}
				/>
				<Tab
					label="Cash Requests"
					value={TAB_HEADER_VALUES.CASH_REQUESTS}
				/>
				<Tab label="Reports" value={TAB_HEADER_VALUES.REPORTS} />
				{isGenSecretary() && (
					<Tab
						label="Funds Overview"
						value={TAB_HEADER_VALUES.FUNDS_OVERVIEW}
					/>
				)}
				<Tab
					label="Manage Inventory"
					value={TAB_HEADER_VALUES.MANAGE_INVENTORY}
				/>
			</Tabs>
		</Paper>
	)
}

export default TabHeader
