export const USER_ROLE = {
	0: 'Non Coastok',
	1: 'Coastok Member',
	2: 'Frontliner',
	3: 'Facilitator',
	4: 'Area Leader',
	5: 'Assistant Director',
	6: 'General Secretary',
	7: 'Director',
}

export const MARITAL_STATUS = {
	1: 'SINGLE',
	2: 'MARRIED',
	3: 'DIVORCED',
	4: 'WIDOWED',
}

export const GENDER = {
	1: 'MALE',
	2: 'FEMALE',
	3: 'OTHER',
}

export const FEST_TYPE = {
	1: 'UDGAAR',
	2: 'VIPLAVA',
	3: 'TEMPLE_CONNECT',
	4: 'COLLEGE_COACHING',
	5: 'VALUE_EDUCATION',
	6: 'CAMP',
	7: 'COASTOK_VIDEOS',
}

export const FILE_TYPE = {
	1: 'AUDIO',
	2: 'VIDEO',
	3: 'IMAGE',
}

export const ADMIN_ROLE = ['No_Access', 'Read', 'Write']

export const QUESTION_CATEGORY = {
	1: 'PERSONAL',
	2: 'PREACHING',
	3: 'OTHER',
}

export const STATUS = ['Default', 'Active', 'Inactive', 'Deleted']

export const ALERT = {
	SUCCESS: 'success',
	ERROR: 'error',
	WARNING: 'warning',
	INFO: 'info',
}

export const PROFESSIONS = [
	'Accountant',
	'Actor',
	'Agriculture and Farm',
	'Aircraft Engineer',
	'Animation',
	'Architect',
	'Artist',
	'Audio and Music',
	'Businessperson',
	'Carpenter and Woodwork',
	'Chartered Accountant and CS',
	'Chef and Cook services',
	'Civil Engineer',
	'Civil Servant',
	'Content and Script',
	'Dietitian',
	'Doctor or Physician',
	'Drama and Filmmaker',
	'Electrical and Electronics',
	'Event Manager',
	'Finance',
	'Flight attendant',
	'Government Services',
	'Graphic Designer',
	'Gym and Fitness',
	'Hotel Management',
	'Humanitarian Worker',
	'Judge',
	'Lawyer',
	'Makeup Artist',
	'Mechanical and Automobile Engineer',
	'Media and Journalism',
	'Medical Services',
	'Merchant Navy',
	'Model',
	'Pharmacist',
	'Photographer',
	'Pilot',
	'Politician',
	'Psychiatrist',
	'Psychologist',
	'Radio Services',
	'Restaurant Services',
	'Sales and Marketing',
	'Scientist',
	'Software Engineer',
	'Sportsperson',
	'Student',
	'Tailor',
	'Teacher',
	'Technician',
	'Travel and Transport Services',
	'Veterinarian',
	'Yoga and Spirituality',
	'Unemployed',
	'Other',
]

export const EXPERTISE_AREAS = [
	'Animation',
	'Art and Painting',
	'Article Writing',
	'Audio and Music',
	'Automobile Driving',
	'Camp or Yatra Management',
	'Computer and MS Office Skills',
	'Cooking',
	'Debate',
	'Design and Decoration',
	'Digital Marketing',
	'Drama',
	'Education and Teaching',
	'Event Management',
	'Facilitation',
	'Fighting and Boxing',
	'Follow Up',
	'Fund Raising',
	'Good Speaking Skills',
	'Graphic Designing',
	'Leadership',
	'Medical Services',
	'Photo and Video Shooting',
	'PPT Making',
	'Public Relationships (PR)',
	'Scientific Research and Preaching',
	'Social Media Platform',
	'Software Programming',
	'Video Editing',
	'Yoga and Exercise',
	'Other',
]

export const CLASS_LEVEL = {
	1: 'VL1',
	2: 'VL2',
	3: 'VL3',
	4: 'NS',
	5: 'SPS',
	6: 'DPS',
	7: 'BVS',
	8: 'SNS',
	9: 'BSS',
	0: 'Other',
}

export const BSS_CAMP_ATTENDED = {
	0: '0',
	1: '1',
	2: '2',
	3: '3',
	'3+': '3+',
}

export const EXPECTED_PBT = {
	2024: '2024',
	2025: '2025',
	2026: '2026',
	2027: '2027',
	'2027+': '2027+',
	'Not decided': 'Not decided',
}

export const EVENT_BOOKING_STATUS = {
	0: 'INACTIVE',
	1: 'PENDING',
	2: 'SUBSIDY_REQUESTED',
	3: 'SUBSIDY_APPROVED',
	4: 'REQUEST_DENIED',
	5: 'ORDERED',
	6: 'PAYMENT_INPROGRESS',
	8: 'FAILED',
	9: 'CASH_PENDING',
	10: 'CASH_CONFIRMED',
	7: 'CONFIRMED',
	11: 'PARTIALLY_PAID',
	12: 'CASH_PARTIALLY_PAID',
	13: 'PREPAID_SUBSIDY_REQUEST',
}

export const ACCOMODATION_CATEGORY = {
	1: {
		id: 1,
		name: 'Dormitory',
		amount: 0,
	},
	2: {
		id: 2,
		name: 'Room',
		amount: 500,
	},
	3: {
		id: 3,
		name: 'Premium Room',
		amount: 1000,
	},
	DORMATORY: { id: 1, name: 'Dormitory', amount: 0 },
	ROOM: { id: 2, name: 'Room', amount: 500 },
	PREMIUM: { PREMIUM: 3, name: 'Premium Room', amount: 1000 },
}

export const FUND_TRANSACTION_STATUS = {
	0: 'INACTIVE',
	1: 'PENDING',
	2: 'ON_HOLD',
	3: 'APPROVED',
	4: 'DELETED',
}

export const EDUCATIONAL_QUALIFICATIONS = [
	{ name: 'High School Diploma', value: 'highSchoolDiploma' },
	{
		name: 'General Educational Development (GED) Certificate',
		value: 'gedCertificate',
	},
	{ name: "Associate's Degree", value: 'associatesDegree' },
	{ name: "Bachelor's Degree", value: 'bachelorsDegree' },
	{ name: "Master's Degree", value: 'mastersDegree' },
	{ name: 'Doctorate (Ph.D.) Degree', value: 'doctorateDegree' },
	{
		name: 'Professional Degree (e.g., MD, JD, DDS)',
		value: 'professionalDegree',
	},
	{
		name: 'Vocational/Technical Certificate',
		value: 'vocationalCertificate',
	},
	{ name: 'Postgraduate Diploma', value: 'postgraduateDiploma' },
	{ name: 'Postgraduate Certificate', value: 'postgraduateCertificate' },
	{ name: 'High School Certificate', value: 'highSchoolCertificate' },
	{ name: 'Undergraduate Certificate', value: 'undergraduateCertificate' },
	{ name: 'Undergraduate Diploma', value: 'undergraduateDiploma' },
	{ name: 'Advanced Diploma', value: 'advancedDiploma' },
	{ name: 'Postdoctoral Degree', value: 'postdoctoralDegree' },
	{ name: 'Graduate Diploma', value: 'graduateDiploma' },
	{ name: 'Honorary Degree', value: 'honoraryDegree' },
	{ name: 'Non-Degree/Certificate Program', value: 'nonDegreeCertificate' },
	{ name: 'Other (for any qualification not listed above)', value: 'other' },
]

export const PAYMENT_METHOD_ONLINE = {
	RAZOR_PAY: 'rzp',
	AXIS_BANK: 'axis',
}

export const LIBRARY_CATEGORY = {
	reading: {
		id: 1,
		text: 'Reading',
	},
	hearing: {
		id: 2,
		text: 'Hearing',
	},
	getById(id) {
		let values = Object.values(this)
		let value = values.find((el) => el.id === id)
		return value
	},
	getAll() {
		return Object.values(this).filter(
			(value) => typeof value !== 'function'
		)
	},
}

export const LIBRARY_MEDIA_TYPE = {
	AUDIO: {
		id: 1,
		text: 'Audio',
	},
	VIDEO: {
		id: 2,
		text: 'Video',
	},
	IMAGE: {
		id: 3,
		text: 'Image',
	},
	LINK: {
		id: 4,
		text: 'Link',
	},
	PDF: {
		id: 5,
		text: 'PDF',
	},
	YTUBE: {
		id: 6,
		text: 'Youtube',
	},
	getById(id) {
		let values = Object.values(this)
		let value = values.find((el) => el.id === id)
		return value
	},
	getAll() {
		return Object.values(this).filter(
			(value) => typeof value !== 'function'
		)
	},
}

export const SADHNA_GROUP = {
	VL1: {
		id: 1,
		text: 'VL1',
	},
	VL2: {
		id: 2,
		text: 'VL2',
	},
	VL3: {
		id: 3,
		text: 'VL3',
	},
	NS: {
		id: 4,
		text: 'NS',
	},
	SPS: {
		id: 5,
		text: 'SPS',
	},
	DPS: {
		id: 6,
		text: 'DPS',
	},
	BVS: {
		id: 7,
		text: 'BVS',
	},
	SNS: {
		id: 8,
		text: 'SNS',
	},
	BSS: {
		id: 9,
		text: 'BSS',
	},
	TRD: {
		id: 10,
		text: 'TRD',
	},
	OTHER: {
		id: 0,
		text: 'Other',
	},
	getById(id) {
		let values = Object.values(this)
		let value = values.find((el) => el.id === id)
		return value
	},
	getAll() {
		return Object.values(this).filter(
			(value) => typeof value !== 'function'
		)
	},
}

export const LIBRARY_LANGUAGE = {
	ENGLISH: {
		id: 1,
		text: 'English',
	},
	HINDI: {
		id: 2,
		text: 'Hindi',
	},
	getById(id) {
		let values = Object.values(this)
		let value = values.find((el) => el.id === id)
		return value
	},
	getAll() {
		return Object.values(this).filter(
			(value) => typeof value !== 'function'
		)
	},
}
