import { useDispatch } from 'react-redux'
import { ROUTE } from '../../constants/route'
import { showAlert } from '../../actions'
import { getUserByUid } from '../../services/viplava'
import { useHistory, useLocation } from 'react-router-dom'
import { ALERT } from '../../constants'
import { getQueryObj, serialize } from '../../utils'
import { GOOGLE_CLIENT_ID } from '../../constants/config'
import { useGoogleLogin } from 'react-google-login'
import { useRef } from 'react'

function useBookNow() {
	const eventRef = useRef()

	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()

	const onLoginSuccess = (res) => {
		localStorage.setItem(
			'USER_DETAILS',
			JSON.stringify({
				email: res.profileObj.email,
				name: res.profileObj.name,
				imageUrl: res.profileObj.imageUrl,
			})
		)
		dispatch(
			showAlert({
				message: 'Please wait. Redirecting you to appropriate page.',
				severity: ALERT.INFO,
				duration: 2000,
			})
		)
		getUserByUid({ email: res.profileObj.email })
			.then(({ result }) => {
				if (result.roles.some((role) => role >= 3)) {
					localStorage.setItem('isViplavaFacilitator', 'yes')
					history.push(
						`${ROUTE.EVENT_BRIDGE_PAGE.path}/${eventRef.current.uuid}`
					)
				} else {
					localStorage.setItem('isViplavaFacilitator', 'no')
					history.push(
						`${ROUTE.EVENT_REGISTER_PAGE.path}/${eventRef.current.uuid}?email=${res.profileObj.email}`
					)
					// setTimeout(function(){
					// 	document.location.href = `${ROUTE.EVENT_REGISTER.path}/${event.uuid}?email=${res.profileObj.email}`;
					// },250);
					window.location.href = `${ROUTE.EVENT_REGISTER_PAGE.path}/${eventRef.current.uuid}?email=${res.profileObj.email}`
				}
			})
			.catch(() => {
				localStorage.setItem('isViplavaFacilitator', 'no')
				// history.push(`${ROUTE.EVENT_REGISTER.path}/${event.uuid}?email=${res.profileObj.email}`)
				window.location.href = `${ROUTE.EVENT_REGISTER_PAGE.path}/${eventRef.current.uuid}?email=${res.profileObj.email}`
			})
	}

	const { signIn, loaded } = useGoogleLogin({
		clientId: GOOGLE_CLIENT_ID,
		onSuccess: onLoginSuccess,
		onFailure: (e) => {
			alert(
				"It seems cookies are not enabled in your browser. Please enable third party cookies and allow popups in browser settings. (One possible reason could be that you're trying to access website in incognito or please check your browser settings)"
			)
		},
	})

	const handleRegisterBtnClick = (event, ...args) => {
		const data = getQueryObj(location?.search)
		eventRef.current = event
		const userProfile = localStorage.getItem('UserProfile')
		if (
			userProfile &&
			JSON.parse(userProfile).roles.some((role) => role >= 3)
		) {
			localStorage.setItem('isViplavaFacilitator', 'yes')
			history.push(
				`${ROUTE.EVENT_BRIDGE_PAGE.path}/${event.uuid}${serialize({
					token: data.token,
				})}`
			)
		} else if (data.token) {
			// window.location.href = `${ROUTE.EVENT_REGISTER_PAGE.path}/${event.uuid}?token=${data.token}`
			localStorage.setItem('isViplavaFacilitator', 'no')
			history.push(
				`${ROUTE.EVENT_REGISTER_PAGE.path}/${event.uuid}?token=${data.token}`
			)
		} else {
			signIn(...args)
		}
	}

	return { handleRegisterBtnClick }
}

export default useBookNow
