import React, { useEffect, useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { getFundBalance, manageFundsRequest } from '../../../services/event'
import '../style.scss'
import {
	Typography,
	Modal,
	Button,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@material-ui/core'
import { LocalAtm, CheckCircleRounded, MoneyOff } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { getAreas, isMobileScreen } from '../../../utils'

const useStyles = makeStyles({
	root: {
		maxWidth: 345,
	},
	media: {
		height: 140,
	},

	modalRoot: {
		// height: 300,
		position: 'absolute',
		flexGrow: 1,
		minWidth: 300,
		transform: 'translateZ(0)',
		// The position fixed scoping doesn't work in IE 11.
		// Disable this demo to preserve the others.
		'@media all and (-ms-high-contrast: none)': {
			display: 'none',
		},
	},
	modal: {
		display: 'flex',
		// padding: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: 400,
		backgroundColor: 'white',
		border: '2px solid #000',
		borderRadius: '8px',
		// boxShadow: theme.shadows[5],
		padding: '40px',
		lineHeight: '1.44',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	reasonInput: {
		height: '88px',
		overflow: 'hidden',
		width: '100%',
		marginTop: '16px',
		padding: '12px',
	},
})

const FundScreen = () => {
	const [filters, setFilters] = useState({
		areaId: 'ALL',
		page: 1,
		per_page: 10,
	})
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [fundsModal, setFundsModal] = useState(null)
	const [fundInput, setFundInput] = useState(0)
	const classes = useStyles()

	useEffect(() => {
		const areaId = filters.areaId === 'ALL' ? undefined : filters.areaId
		setLoading(true)
		getFundBalance(areaId).then((res) => {
			setData(res.data)
			setLoading(false)
		})
	}, [filters.areaId, filters.per_page, filters.page])

	const rowWidthConfig = {
		area: { default: { flex: 0.2 }, mobile: { width: 150 } },
		freeBalance: { default: { flex: 0.2 }, mobile: { width: 150 } },
		holdBalance: { default: { flex: 0.2 } },
		totalBalance: { default: { flex: 0.2 }, mobile: { width: 150 } },
		user: { default: { width: 320 } },
	}

	const addFunds = () => {
		manageFundsRequest({
			amount: fundInput,
			areaId: fundsModal?.id,
			credit: true,
		}).then(() => {
			setData(
				data.map((d) =>
					d._id === fundsModal?.id
						? {
								...d,
								fund: {
									...d.fund,
									totalBalance:
										d.fund.totalBalance + fundInput,
									freeBalance: d.fund.freeBalance + fundInput,
								},
						  }
						: { ...d }
				)
			)
		})
		setFundsModal(null)
		setFundInput(0)
	}

	const removeFunds = () => {
		manageFundsRequest({
			amount: fundInput,
			areaId: fundsModal?.id,
			credit: false,
		}).then(() => {
			setData(
				data.map((d) =>
					d._id === fundsModal?.id
						? {
								...d,
								fund: {
									...d.fund,
									totalBalance:
										d.fund.totalBalance - fundInput,
									freeBalance: d.fund.freeBalance - fundInput,
								},
						  }
						: { ...d }
				)
			)
		})
		setFundsModal(null)
		setFundInput(0)
	}

	const onFilterChange = (name, value) => {
		setFilters((f) => ({
			...f,
			[name]: value,
			page: 1,
		}))
	}

	const headCells = [
		{
			field: 'area',
			numeric: false,
			headerName: 'Area',
			sortable: false,
		},
		{
			field: 'freeBalance',
			numeric: false,
			headerName: 'Free Balance',
			sortable: false,
		},
		{
			field: 'holdBalance',
			numeric: false,
			headerName: 'Hold Balance',
			sortable: false,
		},
		{
			field: 'totalBalance',
			numeric: false,
			headerName: 'Total Balance',
			sortable: true,
		},
		{
			field: 'user',
			headerName: 'Actions',
			sortable: false,
			filterable: false,
			renderCell: (params) => (
				<strong>
					<Button
						variant="contained"
						color="primary"
						startIcon={<LocalAtm />}
						onClick={() =>
							setFundsModal({ ...params.row, credit: true })
						}
						aria-label="Add Fund"
					>
						Add Fund
					</Button>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<Button
						variant="outlined"
						color="primary"
						startIcon={<MoneyOff />}
						onClick={() =>
							setFundsModal({ ...params.row, credit: false })
						}
						aria-label="Remove Fund"
					>
						Remove Fund
					</Button>
				</strong>
			),
		},
	]

	return (
		<div className="screen fund-screen">
			<div className={classes.modalRoot}>
				<Modal
					open={!!fundsModal}
					onClose={() => setFundsModal(null)}
					className={classes.modal}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div className={classes.paper}>
						<Typography
							variant="body2"
							style={{
								textAlign: 'center',
								fontWeight: 'bold',
							}}
						>
							Adding funds to
						</Typography>
						<Typography
							variant="body"
							style={{
								marginBottom: '20px',
								textAlign: 'center',
							}}
						>
							{fundsModal?.name}
						</Typography>
						<div className="balance-info">
							<div>
								<Typography
									variant="body2"
									style={{
										textAlign: 'center',
										fontWeight: 'bold',
									}}
								>
									Free Balance
								</Typography>
								<Typography
									variant="body"
									style={{
										marginBottom: '20px',
										textAlign: 'center',
									}}
								>
									₹
									{fundsModal?.credit
										? fundsModal?.fund?.freeBalance +
										  fundInput
										: fundsModal?.fund?.freeBalance -
										  fundInput}
								</Typography>
							</div>
							<div>
								<Typography
									variant="body2"
									style={{
										textAlign: 'center',
										fontWeight: 'bold',
									}}
								>
									Hold Balance
								</Typography>
								<Typography
									variant="body"
									style={{
										marginBottom: '20px',
										textAlign: 'center',
									}}
								>
									₹{fundsModal?.fund?.holdBalance}
								</Typography>
							</div>
							<div>
								<Typography
									variant="body2"
									style={{
										textAlign: 'center',
										fontWeight: 'bold',
									}}
								>
									Total Balance
								</Typography>
								<Typography
									variant="body"
									style={{
										marginBottom: '20px',
										textAlign: 'center',
									}}
								>
									₹
									{fundsModal?.credit
										? fundsModal?.fund?.totalBalance +
										  fundInput
										: fundsModal?.fund?.totalBalance -
										  fundInput}
								</Typography>
							</div>
						</div>
						<TextField
							className="m-top-16"
							label="Fund Amount"
							variant="outlined"
							defaultValue={fundInput}
							value={fundInput}
							type="number"
							onChange={(e) => setFundInput(+e.target.value)}
							fullWidth
						/>
						<div>
							<Button
								className="m-top-16"
								variant="contained"
								color="primary"
								startIcon={<CheckCircleRounded />}
								onClick={
									fundsModal?.credit ? addFunds : removeFunds
								}
								aria-label="Accept"
								style={{ marginRight: '12px' }}
							>
								{fundsModal?.credit ? 'Add' : 'Remove'} ₹
								{fundInput}
							</Button>
						</div>
					</div>
				</Modal>
			</div>

			<div className="filter-header">
				<FormControl variant="outlined">
					<InputLabel id="demo-simple-select-outlined-label">
						Area
					</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						className="area-input"
						id="demo-simple-select-outlined"
						value={filters.areaId}
						onChange={(e) =>
							onFilterChange(e.target.name, e.target.value)
						}
						label="Area"
						name="areaId"
					>
						<MenuItem value={'ALL'}>All Areas</MenuItem>
						{getAreas().map((area, i) => (
							<MenuItem value={area._id} key={i}>
								{area.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			<DataGrid
				rows={data.map((s) => ({
					...s,
					id: s._id,
					area: s.name,
					freeBalance: s.fund.freeBalance,
					holdBalance: s.fund.holdBalance,
					totalBalance: s.fund.totalBalance,
				}))}
				autoHeight
				columns={headCells.map((col) => ({
					...col,
					sortable: true,
					disableClickEventBubbling: true,
					// resizable: true,
					...(isMobileScreen()
						? rowWidthConfig[col.field].mobile ||
						  rowWidthConfig[col.field].default
						: rowWidthConfig[col.field].default),
				}))}
				pageSize={10}
				rowCount={data.length}
				rowsPerPageOptions={[5, 10, 20, 50, 100]}
				pagination
				disableColumnFilter
				loading={loading}
			/>
		</div>
	)
}

export default FundScreen
