import { Paper, Tab, Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { isGenSecretary } from '../../../utils'

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
	},
})

const TabHeader = ({ value, handleChange }) => {
	const classes = useStyles()

	return (
		<Paper className={classes.root}>
			<Tabs
				value={value}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
			>
				<Tab label="Overview" />
				<Tab label="Subsidy Requests" />
				<Tab label="Cash Requests" />
				<Tab label="Reports" />
				{isGenSecretary() && <Tab label="Funds Overview" />}
				<Tab label="Manage Class" />
			</Tabs>
		</Paper>
	)
}

export default TabHeader
