import {
	Button,
	Modal,
	Fade,
	TextField,
	Backdrop,
	CardHeader,
	CardContent,
	Card,
	CardActions,
} from "@material-ui/core";
import "./style.scss";
// import { ReactMic } from "react-mic";
import React from "react";
import { uploadBlobFile } from "../../utils";
import { updateAnswer } from "../../services/answer-question";

export default class AnswerQuestionModal extends React.Component {
	state = {
		isRecording: false,
		isRecorded: false,
		ansText: "",
		ansAudio: "",
	};

	startRecording = () => {
		this.setState({ isRecording: true, isRecorded: false });
	};

	stopRecording = () => {
		this.setState({ isRecording: false });
	};

	onStop = (recordedBlob) => {
		console.log("recordedBlob is: ", recordedBlob);
		this.blob = recordedBlob;
		this.setState({ isRecorded: true });
	};

	resetRecording = () => {
		this.blob = null;
		this.setState({
			isRecorded: false,
			isRecording: false,
		});
	};

	submitAnswer = (id) => {
		const { ansText } = this.state;
		const userId = JSON.parse(localStorage.getItem("UserProfile"))._id;
		if (this.blob) {
			uploadBlobFile(this.blob.blob).then((res) => {
				updateAnswer(id, {
					ansText,
					ansAudio: res.data.path,
					ansBy: userId,
				}).then((response) => {
					this.blob = null;
					this.setState({
						isRecorded: false,
						modalOpen: false,
					});
				});
			});
		} else {
			updateAnswer(id, {
				ansText,
				ansBy: userId,
			}).then((response) => {
				this.blob = null;
				this.setState({
					isRecorded: false,
					modalOpen: false,
				});
			});
		}
	};

	render() {
		const { modalOpen, question, onClose } = this.props;
		const { ansText, isRecording, isRecorded } = this.state;
		return (
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={modalOpen}
				onClose={onClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={modalOpen}>
					<Card className="c-modal">
						<CardHeader title={question.askText} />
						<CardContent>
							<TextField
								label="Answer"
								multiline
								rows={4}
								variant="outlined"
								value={ansText}
								onChange={(e) => {
									this.setState({ ansText: e.target.value });
								}}
								fullWidth
							/>
							{isRecorded && (
								<audio
									style={{ width: "100%", marginTop: "20px" }}
									src={this.blob.blobURL}
									controls
								/>
							)}
						</CardContent>
						<CardActions>
							{/* <ReactMic
								record={this.state.isRecording}
								className="sound-wave"
								onStop={this.onStop}
								onData={this.onData}
								mimeType="audio/wav"
							/> */}
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									width: "100%",
								}}
							>
								{!isRecorded ? (
									!isRecording ? (
										<div
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<Button
												color="secondary"
												variant="contained"
												onClick={this.startRecording}
												disableElevation
											>
												Record
											</Button>
											<div style={{ margin: "0 20px" }}>or</div>
											<Button color="secondary" variant="outlined">
												Upload Audio
											</Button>
										</div>
									) : (
										<div>
											<Button
												color="secondary"
												variant="contained"
												onClick={this.stopRecording}
											>
												Stop Recording
											</Button>
										</div>
									)
								) : (
									<div
										style={{
											display: "flex",
											alignItems: "center",
										}}
									>
										<Button
											color="secondary"
											variant="contained"
											onClick={this.startRecording}
											disableElevation
										>
											Re-Record
										</Button>
										<div style={{ margin: "0 10px" }}></div>
										<Button
											color="secondary"
											variant="outlined"
											onClick={this.resetRecording}
										>
											Cancel Recording
										</Button>
									</div>
								)}
								<Button
									disabled={isRecording || (!ansText && !isRecorded)}
									color="primary"
									variant="contained"
									onClick={() => this.submitAnswer(question._id)}
								>
									Submit Answer
								</Button>
							</div>
						</CardActions>
					</Card>
				</Fade>
			</Modal>
		);
	}
}
